import React, { Component } from 'react';
import { BgSignup, BgSignupBusiness } from '../../../../assets/img';
import { Form, Container, Row, Card } from 'react-bootstrap';
import SignupForm from './SignupForm';
import BusinessForm from './BusinessForm';
import { connect } from 'react-redux';
import { UserActions } from '../../../../store/actions';
import { toast } from 'react-toastify';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({});

let enhancer = connect(connectState, connectProps);

class SignupWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
      services: [],
      selectedCity: [],
      city: '',
      phoneNumber: '',
      invalidPhone: false,
      invalidZip: false,
      selectedService: '',
      postalCode: '',
      ssc: '',
      licenseNumber: '',
      loader: false,
    };
  }

  submitSignup = async e => {
    e.preventDefault();
    let {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      selectedCity,
      postalCode,
      phoneNumber,
      licenseNumber,
      ssc,
      step,
    } = this.state;

    if (step !== 1) {
      if (password !== passwordConfirmation) {
        toast.error('Passwords not matched');
        return;
      }

      let phone = parsePhoneNumberFromString(phoneNumber, 'US');
      if (!phone.isValid()) {
        this.setState({ invalidPhone: true });
        return;
      } else {
        this.setState({ invalidPhone: false });
      }

      let isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode);
      if (!isValidZip) {
        this.setState({ invalidZip: true });
        return;
      } else {
        this.setState({ invalidZip: false });
      }

      this.setState({ loader: true });

      await this.props.signUp({
        email,
        first_name: firstName,
        last_name: lastName,
        city: selectedCity,
        contact_number: phoneNumber,
        postal_code: postalCode,
        social_security_number: ssc,
        license_number: licenseNumber,
        password,
        password_confirmation: passwordConfirmation,
      });

      this.setState({ loader: false });
    } else this.setState(prevState => ({ step: prevState.step + 1 }));
  };

  setBgImage = step => {
    if (step === 1) return BgSignup;
    else return BgSignupBusiness;
  };

  handleOnChange = (fieldName, value) => {
    console.log('FieldName: ', fieldName);
    console.log('Value: ', value);

    this.setState({ [fieldName]: value });
  };

  handleOnSelectChange = (fieldName, selectedOption) => {
    const { services } = this.state;

    if (fieldName === 'selectedCity')
      this.setState({ city: selectedOption.value, selectedCity: selectedOption });
    else {
      if (selectedOption)
        this.setState({
          services: [...services, selectedOption.value],
        });
      else this.setState({ services: [] });
      this.setState({ selectedService: selectedOption });
    }
  };

  render() {
    const {
      step,
      email,
      password,
      firstName,
      lastName,
      passwordConfirmation,
      services,
      selectedService,
      selectedCity,
      invalidPhone,
      city,
      postalCode,
      invalidZip,
      ssc,
      licenseNumber,
      loader,
    } = this.state;

    return (
      <div
        className="d-flex flex-column justify-content-center"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          minHeight: '100vh',
          backgroundImage: `url(${this.setBgImage(step)})`,
        }}>
        <div className="overlay-block"></div>

        <Form onSubmit={this.submitSignup}>
          <Container>
            <Row className="justify-center p-4 mt-5">
              {step === 1 && (
                <Card className="col-md-6 text-center ml-auto mr-auto card-rounded">
                  <Card.Title className="text-heading mt-4">
                    <h3 className="font-montserrat-Regular">
                      Sign up for
                      <br />
                      <small>service professionals</small>
                    </h3>
                  </Card.Title>

                  <Card.Body>
                    <SignupForm
                      email={email}
                      password={password}
                      firstName={firstName}
                      lastName={lastName}
                      passwordConfirmation={passwordConfirmation}
                      handleOnChange={this.handleOnChange}
                    />
                  </Card.Body>
                </Card>
              )}

              {step === 2 && (
                <Card className="col-md-8 text-center ml-auto mr-auto card-rounded">
                  <Card.Title className="mt-4">
                    <h3 className="font-montserrat-Regular">Tell us more about your business</h3>
                  </Card.Title>

                  <Card.Body>
                    <BusinessForm
                      services={services}
                      selectedService={selectedService}
                      selectedCity={selectedCity}
                      city={city}
                      invalidPhone={invalidPhone}
                      invalidZip={invalidZip}
                      postalCode={postalCode}
                      ssc={ssc}
                      loader={loader}
                      licenseNumber={licenseNumber}
                      handleOnSelectChange={this.handleOnSelectChange}
                      handleOnChange={this.handleOnChange}
                    />
                  </Card.Body>
                </Card>
              )}
            </Row>
          </Container>
        </Form>
      </div>
    );
  }
}

export default enhancer(SignupWizard);
