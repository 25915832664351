import React, { Fragment } from 'react';
import { Row, Form, Button } from 'react-bootstrap';

function Signup({ email, firstName, lastName, password, passwordConfirmation, handleOnChange }) {
  return (
    <Fragment>
      <Form.Group as={Row} controlId="first_name" className="justify-center">
        <div className="text-left w-10/12">
          <Form.Control
            type="text"
            className="border-t-0 border-l-0 border-r-0 font-montserrat-Regular"
            onChange={e => handleOnChange('firstName', e.target.value)}
            value={firstName}
            required
          />

          <label className="form-control-placeholder text-sm" htmlFor="first_name">
            First Name
          </label>
        </div>
      </Form.Group>

      <Form.Group as={Row} controlId="last_name" className="justify-center mt-4">
        <div className="text-left w-10/12">
          <Form.Control
            type="text"
            className="border-t-0 border-l-0 border-r-0 font-montserrat-Regular"
            onChange={e => handleOnChange('lastName', e.target.value)}
            value={lastName}
            required
          />

          <label className="form-control-placeholder text-sm" htmlFor="last_name">
            Last Name
          </label>
        </div>
      </Form.Group>

      <Form.Group as={Row} controlId="email" className="justify-center mt-4">
        <div className="text-left w-10/12">
          <Form.Control
            type="email"
            className="border-t-0 border-l-0 border-r-0 font-montserrat-Regular"
            onChange={e => handleOnChange('email', e.target.value)}
            value={email}
            required
          />

          <label className="form-control-placeholder text-sm" htmlFor="email">
            Email
          </label>
        </div>
      </Form.Group>

      <Form.Group as={Row} controlId="password" className="justify-center mt-4">
        <div className="text-left w-10/12">
          <Form.Control
            type="password"
            className="border-t-0 border-l-0 border-r-0 font-montserrat-Regular"
            onChange={e => handleOnChange('password', e.target.value)}
            value={password}
            required
          />

          <label className="form-control-placeholder text-sm" htmlFor="password">
            Password
          </label>
        </div>
      </Form.Group>

      <Form.Group as={Row} controlId="password_confirmation" className="justify-center mt-4">
        <div className="text-left w-10/12">
          <Form.Control
            type="password"
            className="border-top-0 border-left-0 border-right-0 font-montserrat-Regular"
            onChange={e => handleOnChange('passwordConfirmation', e.target.value)}
            value={passwordConfirmation}
            required
          />

          <label className="form-control-placeholder text-sm" htmlFor="password_confirmation">
            Password Confirmation
          </label>
        </div>
      </Form.Group>

      <Button
        variant=""
        type="submit"
        className="mt-4 professional-btn w-50 text-white font-montserrat-Regular text-xs py-2">
        Next
      </Button>
    </Fragment>
  );
}

export default Signup;
