import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../store/actions';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import cx from 'classnames';

//Public routes
import { App } from '../components/App';

//Client routes
import { ClientSignin } from '../components/Client/SignIn';
import ClientProfile from '../components/Client/Profile';
import { ClientHeader } from '../components/Client/Header';
import { ClientHome } from '../components/Client/Home';
import { Search } from '../components/Client/Search';
import { Hire } from '../components/Client/Hire';

//Athlete routes
import { Header } from '../components/Athlete/Header';
import { ServiceProfessionalSignin } from '../components/Athlete/SignIn/ServiceProfessional';
import { ServiceProfessionalSignup } from '../components/Athlete/SignUp/ServiceProfessional';
import { Bookings, BookingsDetail } from '../components/Athlete/Bookings';
import { Availability } from '../components/Athlete/Availability';
import { Profile, ProfileEdit } from '../components/Athlete/Profile';
import { Payments, PaymentDetail } from '../components/Athlete/Payments';
import { AddCard } from '../components/Athlete/ConnectAccount';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
  openMenu: state.User.current.get('openMenu'),
  userType: state.User.meta.get('userType'),
});

let enhancer = connect(
  connectState,
  connectProps
);

const Routes = props => {
  const userData = props.currentUser && props.currentUser.attributes;

  let openMenu = props.openMenu;
  let userType = props.userType;

  const renderAthleteRoutes = () => {
    return (
      <div>
        <PrivateRoute component={ProfileEdit} path="/service_professional/profile/edit" exact />

        <PrivateRoute component={Profile} path="/service_professional/profile" exact />

        <PrivateRoute component={Payments} path="/payments" exact />

        <PrivateRoute component={PaymentDetail} path="/payments/detail" exact />

        <PrivateRoute component={Bookings} path="/service_professional/bookings" exact />

        <PrivateRoute component={BookingsDetail} path="/service_professional/bookings/:id" exact />

        <PrivateRoute component={Availability} path="/service_professional/availability" exact />

        <PrivateRoute
          component={Availability}
          path="/service_professional/availability/:month"
          exact
        />

        <PrivateRoute component={AddCard} path="/service_professional/card" exact />
      </div>
    );
  };

  const renderClientRoutes = () => {
    return (
      <div>
        <PublicRoute component={ClientProfile} path="/client/profile" exact />
        <PublicRoute component={ClientHome} path="/client/home" exact />
        <PublicRoute component={Search} path="/client/search" exact />
        <PublicRoute component={Hire} path="/client/hire" exact />
      </div>
    );
  };

  return (
    <Router>
      <Route
        render={({ location, history }) => (
          <React.Fragment>
            {userType === 'athlete' && (
              <div
                className={cx(openMenu ? 'open-nav-pannel' : 'close-nav-pannel', {
                  'd-none': !userData || !userData.first_name,
                })}>
                <Header />
              </div>
            )}

            {userType === 'client' && <ClientHeader />}

            <div>
              <Switch>
                <PublicRoute restricted={true} component={App} path="/" exact />

                <PublicRoute
                  restricted={true}
                  component={ServiceProfessionalSignin}
                  path="/athlete/login"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={ClientSignin}
                  path="/client/login"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={ServiceProfessionalSignup}
                  path="/service_professional/signup"
                  exact
                />

                {userType === 'athlete' && renderAthleteRoutes()}

                {/* {userType === 'client' && renderClientRoutes()} */}

                {renderClientRoutes()}
              </Switch>
            </div>
          </React.Fragment>
        )}
      />
    </Router>
  );
};

export default enhancer(Routes);
