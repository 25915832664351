import api from '../api';
import { client } from '../index';
import { showHUD, hideHUD } from '../User/actions';
import qs from 'qs';

export const getBookings = (page = 1) => async dispatch => {
  let payload = {
    page,
    per_page: 10,
  };

  let paramsString = qs.stringify(payload);
  dispatch(showHUD());
  const { data } = await client().get(`${api.bookings()}?${paramsString}`);
  dispatch(hideHUD());
  dispatch({ type: 'SET_BOOKINGS', payload: { data: data.data } });
  dispatch({ type: 'SET_BOOKINGS_PAGY', payload: { data: data.pagy } });
};

export const getSingleBooking = id => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().get(`${api.bookings()}/${id}`);

  dispatch(hideHUD());
  dispatch({ type: 'SET_SINGLE_BOOKING', payload: { data: data.data } });
};

export const updateStatus = (id, status, fetchBooking) => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().put(`${api.bookings()}/${id}`, {
    booking: {
      status,
    },
  });

  dispatch(hideHUD());
  dispatch(getSingleBooking(id));
  dispatch({ type: 'UPDATE_BOOKING', payload: { data: data.data } });
};
