import React, { Component } from 'react';
import { UserActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { BgSignup } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCalendar, faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({});

let enhancer = connect(connectState, connectProps);

const ProgressCircle = props => {
  return (
    <div class="flex-1">
      <div
        class={`w-8 h-8 ${
          props.fill ? 'bg-blue-800' : 'bg-white border-2 border-gray-700'
        }  mx-auto rounded-full flex items-center`}
      />
    </div>
  );
};

const ProgressBar = props => {
  return (
    <div
      class={`${
        props.move ? 'w-1/6 trans' : 'w-0'
      } align-center items-center align-middle content-center flex`}>
      <div
        class={`w-full ${
          props.fill ? 'bg-blue-800' : 'border-2 border-gray-400 '
        } rounded items-center align-middle align-center flex-1`}>
        <div
          class="text-xs leading-none py-1 text-center text-grey-darkest rounded"
          style={{ width: '20%' }}></div>
      </div>
    </div>
  );
};

class PaymentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      move: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ move: true });
    }, 100);
  }

  render() {
    return (
      <div style={{ backgroundColor: 'F2F2F2' }} fluid>
        <div className="container mx-auto pb-5">
          <div className="row justify-center">
            <div className="col-md-11">
              <div className="row justify-between pt-3 pb-3">
                <h3 className="font-semibold">Details</h3>
              </div>

              <div className="rounded-t solid-blue h-12 mt-1 row items-center px-4">
                <h1 className="text-white">Booking details</h1>
              </div>

              <div className="bg-white shadow row">
                <div className="pt-3 w-100">
                  <div class="flex pb-1">
                    <div class="flex-1" />

                    <ProgressCircle fill />

                    <ProgressBar move={this.state.move} fill />

                    <ProgressCircle fill />

                    <ProgressBar move={this.state.move} fill />

                    <ProgressCircle fill />

                    <ProgressBar move={this.state.move} />

                    <ProgressCircle fill={false} />

                    <div class="flex-1" />
                  </div>

                  <div class="flex text-xs content-center text-center">
                    <div class="w-1/4">Requested</div>

                    <div class="w-1/4">Paid</div>

                    <div class="w-1/4">Accepted</div>

                    <div class="w-1/4">Complete</div>
                  </div>
                </div>

                <div className="my-4 w-100 px-12 mx-24">
                  <div className="w-100 flex flex-row sm:flex-column justify-between items-center pt-2 rounded-lg">
                    <div class="w-100 flex flex-wrap">
                      <div class="w-full md:w-1/2 lg:w-1/2">
                        <div className="flex flex-row items-center">
                          <img src={BgSignup} className="rounded-full h-16 w-16" />

                          <div className="ml-4">
                            <h3 className="font-normal text-gray-500">Service:</h3>

                            <h4 className="text-sm pt-2">Basic Manicure</h4>
                          </div>
                        </div>
                      </div>

                      <div class="w-full md:w-1/2 lg:w-1/2 flex flex-column items-end justify-center">
                        <h3 className="font-normal text-sm text-gray-500">Price</h3>

                        <h4 className="text-sm pt-2">20 $</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="w-full md:w-1/2 lg:w-1/2">
                  <div className="flex flex-column px-4 shadow-2xl bg-white   py-4">
                    <h3 className="font-semibold mb-2">Appointment details</h3>

                    <a className="py-2">
                      <FontAwesomeIcon size="xs" icon={faHome} />
                      <span className="ml-1 text-xs">
                        {' '}
                        {'booking && booking.attributes.client.data.attributes.address'}
                      </span>
                    </a>

                    <a className="py-2">
                      <FontAwesomeIcon size="xs" icon={faCalendar} />
                      <span className="ml-1 text-xs">2010-01-01</span>
                    </a>

                    <a className="py-2">
                      <FontAwesomeIcon size="xs" icon={faPhone} />
                      <span className="ml-1 text-xs">03206565111</span>
                    </a>

                    <a className="py-2">
                      <FontAwesomeIcon size="xs" icon={faEnvelope} />
                      <span className="ml-1 text-xs">abc@mail.com</span>
                    </a>
                  </div>
                </div>

                <div className="bg-primary w-full md:w-1/2 lg:w-1/2 shadow">
                  <div className="flex flex-column  bg-gray-700 text-center h-24 justify-center">
                    <h1 className="text-white">Paid 16$</h1>
                  </div>

                  <div className="flex flex-column bg-opacity-75 bg-gray-600 text-center h-16 justify-center">
                    <small className="text-white">24 June 2020</small>
                    <small className="text-white">#4422</small>
                  </div>

                  <div className="bg-white p-3">
                    <h3 className="font-bold">Summary</h3>

                    <div className="flex flex-row justify-between pt-3">
                      <small>Basic manicure</small>
                      <small>$17.00</small>
                    </div>

                    <div className="flex flex-row justify-between pt-2">
                      <small>Subtotal</small>
                      <small className="font-bold">$17.00</small>
                    </div>

                    <div className="flex flex-row justify-between pt-2">
                      <small>Tax</small>
                      <small className="font-bold">$0.00</small>
                    </div>

                    <div className="flex flex-row justify-between pt-2">
                      <small>Grand total</small>
                      <small className="font-bold">$17.00</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(PaymentDetail);
