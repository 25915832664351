import React, { useState } from 'react';
import { Container, Row, Form, Col, Button } from 'react-bootstrap';

function ProfileEdit() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const submitProfile = e => {
    e.preventDefault();
    console.log('Email: ', email);
  };

  return (
    <Container className="container-top">
      <Row className="justify-content-center">
        <Col md={5}>
          <Form onSubmit={submitProfile}>
            <h5 className="font-montserrat-bold text-center">Account Details</h5>

            <Form.Group as={Row} controlId="email" className="mt-4">
              <Form.Label className="font-montserrat-medium">Email</Form.Label>

              <Form.Control
                type="text"
                className="font-montserrat-Regular"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Row} controlId="first_name">
              <Form.Label className="font-montserrat-medium">First Name</Form.Label>

              <Form.Control
                type="text"
                className="font-montserrat-Regular"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Row} controlId="last_name">
              <Form.Label className="font-montserrat-medium">Last Name</Form.Label>

              <Form.Control
                type="text"
                className="font-montserrat-Regular"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Row} controlId="contact_number">
              <Form.Label className="font-montserrat-medium">Contact Number</Form.Label>

              <Form.Control
                type="text"
                className="font-montserrat-Regular"
                value={contactNumber}
                onChange={e => setContactNumber(e.target.value)}
              />
            </Form.Group>

            <h5 className="font-montserrat-bold text-center">Passwords</h5>

            <Form.Group as={Row} controlId="current_password">
              <Form.Label className="font-montserrat-medium">Current Password</Form.Label>

              <Form.Control
                type="password"
                className="font-montserrat-Regular"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Row} controlId="new_password">
              <Form.Label className="font-montserrat-medium">New Password</Form.Label>

              <Form.Control
                type="password"
                className="font-montserrat-Regular"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
            </Form.Group>

            <Row className="mb-5 justify-content-center">
              <Button
                variant=""
                type="submit"
                className="mt-3 btn text-white font-montserrat-Regular float-right">
                Submit Changes
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfileEdit;
