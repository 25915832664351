import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';
import { Loader } from '../Loader';

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  bookings: state.Bookings.booking.get('bookings'),
  bookingsPagy: state.Bookings.booking.get('bookingsPagy'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

const renderHeadings = () => {
  const heads = [
    'Order id',
    'Customer name',
    'Order Summary',
    'Customer Contact',
    'Total',
    'Actions',
  ];
  return heads.map(item => {
    return <th class="text-center light-mud text-sm px-4 py-2">{item}</th>;
  });
};

class Bookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
    };
  }

  componentDidMount() {
    let { getBookings, location } = this.props;
    if (location.search !== '') {
      getBookings(location.search.split('=')[1]);
    } else {
      getBookings();
    }
  }

  moveToDetail(id) {
    let { history } = this.props;

    history.push({
      pathname: `/service_professional/bookings/${id}`,
    });
  }

  handlePageClick = data => {
    let { getBookings, history } = this.props;
    history.push(`/service_professional/bookings?page=${data.selected + 1}`);
    getBookings(data.selected + 1);
  };

  render() {
    let { bookings, bookingsPagy, updateStatus, loader } = this.props;
    let { searchText } = this.state;

    let filteredBookings =
      searchText !== ''
        ? bookings.filter(booking =>
            booking.attributes.client.data.attributes.contact_number
              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
        : bookings;

    return (
      <div style={{ backgroundColor: 'F2F2F2' }} fluid>
        <div className="container mx-auto">
          <div className="row justify-center">
            <div className="col-md-11">
              <div className="row justify-between pt-3 pb-3">
                <h3 className="font-semibold">Bookings</h3>

                <div class="bg-white flex flex-row  w-2/5 px-4 shadow rounded items-center">
                  <FontAwesomeIcon icon={faSearch} className="gray-icon" />

                  <input
                    class="border-none focus:outline-none font-thin appearance-none w-full py-2 px-3 text-gray-700"
                    type="search"
                    onChange={e => this.setState({ searchText: e.target.value })}
                    placeholder="Search order"
                  />
                </div>
              </div>

              <div className="rounded-t solid-blue h-10 mt-2 row items-center px-4">
                <h1 className="text-white">All Bookings</h1>
              </div>

              <div className="row">
                <table class="table-auto bg-white w-100">
                  <thead>
                    <tr className="tr-bg-color">{renderHeadings()}</tr>
                  </thead>

                  <tbody>
                    {filteredBookings.length > 0 ? (
                      filteredBookings.map(booking => (
                        <tr className="border-b-2" onClick={() => this.moveToDetail(booking.id)}>
                          <td class="text-center font-hairline py-2 text-xs">{booking.id}</td>

                          <td class="text-center font-hairline text-xs">
                            {booking.attributes.client.data.attributes.first_name}{' '}
                            {booking.attributes.client.data.attributes.last_name}
                          </td>

                          <td class="text-center font-thin text-xs">
                            {booking.attributes.booked_services.data.map(service => {
                              return (
                                <p>{service.attributes.provided_service.data.attributes.name}</p>
                              );
                            })}
                          </td>

                          <td class="font-hairline text-center text-xs">
                            {booking.attributes.client.data.attributes.contact_number}
                          </td>

                          <td class="text-center text-xs font-thin">
                            {booking.attributes.booked_services.data.map(service => {
                              return (
                                <p>${service.attributes.provided_service.data.attributes.price}</p>
                              );
                            })}
                          </td>

                          <td class="text-center text-xs font-thin">
                            {booking.attributes.status === 'pending' ? (
                              <div className="flex flex-row justify-center items-center">
                                <button
                                  onClick={e => {
                                    e.stopPropagation();
                                    updateStatus(booking.id, 'Accepted');
                                  }}
                                  class="hover:text-blue-300 text-blue-500 text-xs py-2 px-1 mx-1">
                                  Accept
                                </button>

                                <button
                                  onClick={e => {
                                    e.stopPropagation();
                                    updateStatus(booking.id, 'Rejected');
                                  }}
                                  class="hover:text-red-500 text-red-700 text-xs py-2 px-4 mx-2 hover:border-blue-500 rounded">
                                  Reject
                                </button>
                              </div>
                            ) : (
                              <p className="capitalize">{booking.attributes.status}</p>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          <div fluid className="p-4">
                            {loader ? (
                              <Loader />
                            ) : (
                              <h3 className={'text-center'}>No Bookings Available</h3>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {bookingsPagy && (
            <div className="row md-col-12 mt-3">
              <ReactPaginate
                previousLabel={'← Previous'}
                nextLabel={'Next →'}
                breakLabel={<span className="gap">...</span>}
                pageCount={bookingsPagy.pages}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={'pagination'}
                previousLinkClassName={'previous_page'}
                nextLinkClassName={'next_page'}
                disabledClassName={'disabled'}
                activeClassName={'active'}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default enhancer(Bookings);
