import { combineReducers } from 'redux';
import { Map } from 'immutable';

const bookingsDefault = {
  bookings: [],
  booking: null,
  bookingsPagy: null,
};

const bookingsReducer = (state = Map(bookingsDefault), action) => {
  switch (action.type) {
    case 'SET_BOOKINGS':
      return state.set('bookings', action.payload.data);

    case 'SET_BOOKINGS_PAGY':
      return state.set('bookingsPagy', action.payload.data);

    case 'SET_SINGLE_BOOKING':
      return state.set('booking', action.payload.data);

    case 'UPDATE_BOOKING':
      return state.update('bookings', bookings =>
        bookings.map(booking => {
          if (booking.id === action.payload.id) booking.attributes = action.payload.attributes;

          return booking;
        })
      );

    default:
      return state;
  }
};

export default combineReducers({
  booking: bookingsReducer,
});
