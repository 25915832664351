import { combineReducers } from 'redux';
import { Map } from 'immutable';

const calendarDefault = {
  availabilities: [],
  availabilityPagy: null,
  entries: [],
};

const calendarReducer = (state = Map(calendarDefault), action) => {
  switch (action.type) {
    case 'SET_AVAILABILITIES':
      return state.set('availabilities', action.payload.data);

    case 'SET_ENTRIES':
      return state.set('entries', action.payload.data);

    default:
      return state;
  }
};

export default combineReducers({
  calendar: calendarReducer,
});
