import React from 'react';
import { connect } from 'react-redux';
import { BgProfile, BgSignupBusiness } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { ClientHeader } from '../Header';

let connectProps = {};
let connectState = state => ({});

let enhancer = connect(
  connectState,
  connectProps
);

const AtheleteDetail = ({ title, value }) => (
  <div className="flex justify-between my-2">
    <p4 className="text-white opacity-50 text-sm">{title}</p4>
    <p4 className="text-white opacity-50 text-sm">{value}</p4>
  </div>
);

function Profile(props) {
  return (
    <div className="bg-local min-h-screen" style={{ backgroundImage: `url(${BgProfile})` }}>
      <ClientHeader />
      <div class="flex p-4 flex-col md:flex-row">
        <div class="w-9/12 md:w-2/4 bg-primary-color flex flex-col items-center py-8 px-4">
          <img src={BgSignupBusiness} className="rounded-full h-20 w-20 mb-2 border" />
          <p4 className="font-montserrat-bold text-white text-center">CHRISTIAN PEREZ</p4>
          <p6 className="text-white opacity-50 text-xs mb-1">Quarterback</p6>
          <p6 className="text-white opacity-50 text-xs">Freshman</p6>
          <div>
            <FontAwesomeIcon icon={faMapMarkerAlt} size="xs" className="gray-icon mr-1" />
            <p6 className="text-white opacity-50 text-xs">East St. Louis, Mo.</p6>
          </div>

          <button
            onClick={() => {}}
            class="my-4 w-9/12 rounded-full bg-cus-yellow hover:bg-white font-normal py-2 px-4 text-sm">
            Hire
          </button>

          <div className="w-9/12 mb-10">
            <AtheleteDetail title={'Height'} value={'6 - 3'} />
            <AtheleteDetail title={'Weight'} value={'228'} />
            <AtheleteDetail title={'Training'} value={'Mechanics'} />
            <AtheleteDetail title={'Hometown'} value={'East St. Louis, Mo.'} />
            <AtheleteDetail title={'Years'} value={'Freshman'} />
          </div>
        </div>
        <div class="w-3/4 p-4 md:mx-10">
          <div className="border-b border-gray-400 py-4 mb-4">
            <button
              onClick={() => {}}
              class="mr-3 rounded-full bg-secondary-color text-white text-xs py-2 px-8 hover:bg-yellow-500 font-normal my-2 md:my-0">
              About
            </button>
            <button
              onClick={() => {}}
              class="rounded-full bg-primary-color text-white text-xs py-2 px-8 hover:bg-gray-500 font-normal my-2 md:my-0">
              More
            </button>
          </div>
          <p6 className="text-white text-xs">
            Played at East St. Louis High … attended the Naval Academy before joining the Louisville
            program … ranked as the No. 98 dual-threat QB and No. 83 player in the state of
            Illinois, while the 247Sports Composite ranking had him as the No. 95 QB and No. 79
            player in Illinois. … threw for more than 1,700 yards for East St. Louis as a senior
            during the 2017 season and tallied 22 touchdowns to only one interceptio
          </p6>
        </div>
      </div>
    </div>
  );
}

export default enhancer(Profile);
