import api from '../api';
import { setToken, unsetToken } from './helpers';
import { client } from '../index';

export const showHUD = () => {
  return { type: 'SHOW_HUD' };
};

export const hideHUD = () => {
  return { type: 'HIDE_HUD' };
};

export const setUserType = value => async dispatch => {
  dispatch({ type: 'SET_USER_TYPE', payload: { value } });
};

export const setCurrentUser = data => {
  let user = data;
  localStorage.setItem('auth_token', user.attributes.auth_token);
  setToken(user.attributes.auth_token);

  return {
    type: 'SET_CURRENT_USER',
    payload: user,
  };
};

export const signUp = payload => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().post(api.signUp(), { service_professional: payload });
  dispatch(hideHUD());

  dispatch(setCurrentUser(data.data));
};

export const signIn = payload => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().post(api.signIn(), payload);
  dispatch(hideHUD());

  dispatch(setCurrentUser(data.data));
};

export const logoutPressed = () => async dispatch => {
  let user = {};
  unsetToken();
  dispatch({ type: 'SET_CURRENT_USER', payload: { user } });
};

export const changeMenuState = value => async dispatch => {
  dispatch({ type: 'CHANGE_MENU_STATE', payload: { value } });
};

export const createAccount = payload => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().post(api.account(), payload);
  dispatch(hideHUD());
  return data;
};
