import React, { Fragment } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { cities } from '../../../../constants';
import { Loader } from '../../Loader';

function BusinessForm({
  selectedService,
  selectedCity,
  postalCode,
  ssc,
  licenseNumber,
  phoneNumber,
  invalidPhone,
  invalidZip,
  handleOnSelectChange,
  handleOnChange,
  loader,
}) {
  const servicesOptions = [
    { value: 'Nails', label: 'Nails' },
    { value: 'Basic Manicure', label: 'Basic Manicure' },
    { value: 'Paraffine Manicure', label: 'Paraffine Manicure' },
    { value: 'Gel Manicure', label: 'Gel Manicure' },
    { value: 'Acrylic Manicure', label: 'Acrylic Manicure' },
    { value: 'French Manicure', label: 'French Manicure' },
    { value: 'Shellac Manicure', label: 'Shellac Manicure' },
  ];

  return (
    <Fragment>
      <div className="flex-column text-left">
        <div className="flex-row">
          <label className="font-montserrat-Regular">What services you provide?</label>
        </div>
      </div>

      {/* <Form.Group as={Row} controlId="services">
        <Col md={12}>
          <Select
            className="font-montserrat-Regular"
            isMulti={true}
            isSearchable={true}
            value={selectedService}
            onChange={selectedOption => handleOnSelectChange('services', selectedOption)}
            options={servicesOptions}
          />
        </Col>
      </Form.Group> */}

      <div className="flex">
        <div className="w-10/12 flex-column">
          <div className="flex-row text-left">
            <label className="font-montserrat-Regular">Select City</label>
          </div>
        </div>
      </div>

      <Form.Group controlId="city">
        <div className="flex">
          <div className="w-full">
            <Select
              className="font-montserrat-Regular"
              isSearchable={true}
              value={selectedCity}
              onChange={selectedOption => handleOnSelectChange('selectedCity', selectedOption)}
              options={cities}
            />
          </div>
        </div>
      </Form.Group>

      <Form.Group as={Row} controlId="postal_code" className="mt-4">
        <div className="flex flex-wrap">
          <div class="w-1/4 mx-2 text-left">
            <small className="font-montserrat-Regular text-left">Enter Phone number</small>

            <Form.Control
              type="number"
              className="font-montserrat-Regular rounded"
              isInvalid={invalidPhone}
              onChange={e => handleOnChange('phoneNumber', e.target.value)}
              required
            />

            <p className={`${invalidPhone ? 'text-center' : 'd-none'}`}>
              <small className="font-montserrat-Regular text-xs text-red-700">
                Invalid phone number
              </small>
            </p>
          </div>

          <div class="w-1/4  mx-2  text-left">
            <small className="font-montserrat-Regular">Enter Postal Code</small>

            <Form.Control
              type="text"
              className="font-montserrat-Regular rounded"
              isInvalid={invalidZip}
              onChange={e => handleOnChange('postalCode', e.target.value)}
              required
            />
            <p className={`${invalidZip ? 'text-center' : 'd-none'}`}>
              <small className="font-montserrat-Regular text-xs text-red-700">
                Invalid postal code
              </small>
            </p>
          </div>

          <div class="w-1/4 text-left  mx-2">
            <small className="font-montserrat-Regular">Enter social security code</small>

            <Form.Control
              type="number"
              className="font-montserrat-Regular rounded"
              onChange={e => handleOnChange('ssc', e.target.value)}
              required
            />
          </div>

          <div class="w-1/4 text-left mt-2 mx-2">
            <small className="font-montserrat-Regular">Enter license number</small>

            <Form.Control
              type="number"
              className="font-montserrat-Regular rounded"
              onChange={e => handleOnChange('licenseNumber', e.target.value)}
              required
            />
          </div>
        </div>
      </Form.Group>

      <Button
        variant=""
        disabled={loader}
        type="submit"
        className="mt-4 btn w-25 text-white font-montserrat-Regular text-xs py-2">
        Submit
      </Button>

      {loader && (
        <div className="flex justify-center items-center">
          <Loader small />
        </div>
      )}
    </Fragment>
  );
}

export default BusinessForm;
