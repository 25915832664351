import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../../store/actions';
import LoginForm from '../../../Shared/LoginForm';

let connectProps = {
  ...UserActions,
};

let enhancer = connect(
  null,
  connectProps
);

function Signin(props) {
  const [loader, setLoader] = useState(false);

  const submitSignin = async data => {
    const email = data.email;
    const password = data.password;
    setLoader(true);
    await props.signIn({ email, password });
    props.setUserType('athlete');
    setLoader(false);
  };

  let history = useHistory();
  return (
    <div>
      <LoginForm
        loader={loader}
        loginParent={data => submitSignin(data)}
        signupParent={() => {
          history.push('/service_professional/signup');
        }}
      />
    </div>
  );
}

export default enhancer(Signin);
