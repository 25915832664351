import React, { Component } from 'react';
import { UserActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({});

let enhancer = connect(connectState, connectProps);

class Payments extends Component {
  renderHeadings() {
    const heads = ['Booking id', 'Customer name', 'Payment date', 'Amount', 'Details', 'Status'];
    return heads.map(item => {
      return <th class="border text-center light-mud text-sm px-4 py-2">{item}</th>;
    });
  }

  navigateToBooking() {
    const { history } = this.props;
    history.push('/payments/detail');
  }

  render() {
    return (
      <div style={{ backgroundColor: 'F2F2F2' }} fluid>
        <div className="container mx-auto">
          <div className="row justify-center">
            <div className="col-md-11">
              <div className="row justify-between pt-3 pb-3">
                <h3 className="font-semibold">Payments</h3>

                <div class="bg-white flex flex-row  w-2/5 px-4 shadow rounded items-center">
                  <FontAwesomeIcon icon={faSearch} className="gray-icon" />

                  <input
                    class="border-none focus:outline-none font-thin appearance-none w-full py-2 px-3 text-gray-700"
                    type="search"
                    placeholder="Search"
                  />
                </div>
              </div>

              <div className="rounded-t solid-blue h-10 mt-6 row items-center px-4">
                <h1 className="text-white">All Payments</h1>
              </div>

              <div className="row">
                <table class="table-auto bg-white w-100">
                  <thead>
                    <tr>{this.renderHeadings()}</tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td class="border px-4 py-2 text-center font-hairline text-xs text-center font-hairline text-xs">
                        # 5574
                      </td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs ">Adam</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">858</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">1,280</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs text-blue-500">
                        <a onClick={() => this.navigateToBooking()}>
                          <FontAwesomeIcon icon={faEye} />
                          <span className="font-bold ml-1"> Booking Details</span>
                        </a>
                      </td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">Completed</td>
                    </tr>

                    <tr class="bg-gray-100">
                      <td class="border px-4 py-2 text-center font-hairline text-xs"># 5574</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">Adam</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">112</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">-</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs text-blue-500">
                        <a onClick={() => this.navigateToBooking()}>
                          <FontAwesomeIcon icon={faEye} />
                          <span className="font-bold ml-1"> Booking Details</span>
                        </a>
                      </td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">Pending</td>
                    </tr>

                    <tr>
                      <td class="border px-4 py-2 text-center font-hairline text-xs"># 5574</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">Chris</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">1,280</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">1,280</td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs text-blue-500">
                        <a onClick={() => this.navigateToBooking()}>
                          <FontAwesomeIcon icon={faEye} />
                          <span className="font-bold ml-1"> Booking Details</span>
                        </a>
                      </td>
                      <td class="border px-4 py-2 text-center font-hairline text-xs">Completed</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row md-col-12 absolute inset-x-0 bottom-0">
            <ReactPaginate
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              breakLabel={<span className="gap">...</span>}
              pageCount={22}
              // onPageChange={this.handlePageClick}
              forcePage={2}
              containerClassName={'pagination'}
              previousLinkClassName={'previous_page'}
              nextLinkClassName={'next_page'}
              disabledClassName={'disabled'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(Payments);
