import React, { useState } from 'react';
import { connect } from 'react-redux';
import './Search.scss';
import DatePicker from 'react-datepicker';
import { BgSignupBusiness } from '../../../assets/img';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { ClientHeader } from '../Header';
import { useHistory } from 'react-router-dom';

let connectProps = {};
let connectState = state => ({});

let enhancer = connect(
  connectState,
  connectProps
);

const Checkbox = ({ checked, onClick, title }) => (
  <div className="bg-dark-gray p-1 px-3 flex items-center justify-center mr-4 mb-2 md:mb-0">
    <label class="inline-flex items-center mt-2">
      <input
        type="checkbox"
        class="form-checkbox h-4 w-4"
        checked={checked}
        onClick={() => onClick()}
      />
      <span class="ml-2 text-gray-500 text-sm">{title}</span>
    </label>
  </div>
);

function Search(props) {
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [availToday, setAvailToday] = useState(false);
  const [availWeek, setAvailWeek] = useState(false);
  const history = useHistory();

  return (
    <div>
      <ClientHeader />

      <div className="bg-black min-h-screen p-5">
        <div className="w-full flex justify-center mb-3">
          <form class="w-1/2 md:w-6/12">
            <div class="mb-6">
              <div class="flex flex-col md:flex-row bg-dark-gray rounded-l-md">
                <input
                  class="bg-dark-gray md:rounded-l-md appearance-none py-2 px-4 text-white text-xs focus:outline-none"
                  id="inline-full-name"
                  type="text"
                  value="Jane Doe"
                />
                <DatePicker
                  className="bg-dark-gray shadow py-2 text-xs text-white px-2"
                  selected={new Date()}
                  onChange={date => {}}
                  // minDate={date}
                />
                <button onClick={() => {}} className="bg-cus-gray text-xs px-5 w-full">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col md:flex-row">
            <Checkbox checked={male} onClick={() => setMale(!male)} title={'Male'} />
            <Checkbox checked={female} onClick={() => setFemale(!female)} title={'Female'} />
            <Checkbox
              checked={availToday}
              onClick={() => setAvailToday(!availToday)}
              title={'Available today'}
            />
            <Checkbox
              checked={availWeek}
              onClick={() => setAvailWeek(!availWeek)}
              title={'Available this week'}
            />
          </div>

          <div className="flex flex-row items-center">
            <p4 className="text-gray-500 text-sm mr-3">Sort by:</p4>
            <div class="inline-block relative">
              <select class="block appearance-none text-gray-500 text-sm bg-dark-gray px-3 p-2 pr-2 shadow leading-tight focus:outline-none focus:shadow-outline">
                <option>Relevance</option>
                <option>Option 2</option>
                <option>Option 3</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex py-4 flex-col md:flex-row">
          <div class="w-full md:w-9/12 flex flex-col items-center">
            {['a', 'b', 'c', 'd'].map(item => (
              <div className="bg-primary-color py-4 px-4 w-full flex flex-col md:flex-row justify-between my-2">
                <div className="flex flex-row items-center">
                  <img src={BgSignupBusiness} className="rounded-full h-20 w-20 mb-2 border" />
                  <div className="flex flex-col ml-3">
                    <p4 className="font-montserrat-bold text-white text-center text-sm">
                      CHRISTIAN PEREZ
                    </p4>
                    <div>
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="gray-icon mr-1 caption-text"
                      />
                      <span className="caption-text">East St. Louis, Mo.</span>
                    </div>
                    <span className="caption-text">Quarterback</span>
                    <span className="caption-text">Freshman</span>
                  </div>
                </div>

                <div className="flex flex-col">
                  <button
                    onClick={() => {
                      history.push('/client/hire');
                    }}
                    class="mr-3 rounded-md bg-secondary-color text-white text-xs py-2 px-8 hover:bg-yellow-500 font-normal my-2 md:my-0">
                    Hire
                  </button>
                  <button
                    onClick={() => {}}
                    class="mr-3 rounded-md bg-cus-gray text-black text-xs py-2 px-8 hover:bg-yellow-500 font-normal my-2 md:my-0">
                    Details
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div class="w-full md:w-2/6">
            <div id="mapBox" className="my-2">
              <Map
                google={props.google}
                zoom={2}
                style={{ height: 400, position: 'relative' }}
                initialCenter={{ lat: 47.444, lng: -122.176 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAU5JJtafm5w0Rcw6vJnQK8NgBuE8xhOK0',
})(enhancer(Search));
