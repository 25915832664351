import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faMoneyBillAlt,
  faCalendar,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  openMenu: state.User.current.get('openMenu'),
});

let enhancer = connect(
  connectState,
  connectProps
);

function Header(props) {
  const history = useHistory();

  const logout = () => {
    props.logoutPressed();
    localStorage.clear();
    props.setUserType(null);
    history.push('/');
  };

  let openMenu = props.openMenu;

  return (
    <Navbar className="solid-blue" expand="lg">
      <div className="container" fluid>
        <Navbar.Brand href="#home" className="font-montserrat-bold text-white">
          <a
            style={{
              fontSize: '25px',
              color: 'white',
            }}
            href="javascript:void(0)"
            className="closebtn mr-4"
            onClick={() => props.changeMenuState(true)}>
            &#9776;
          </a>
          
          <span>
            1v1
            <small style={{ fontWeight: 'normal' }} className="">
              {' '}
              Athletics Dashboard
            </small>
          </span>
        </Navbar.Brand>

        <div>
          <Nav className="top-nav">
            <a
              style={{ justifyContent: 'center', alignSelf: 'center' }}
              href={'/service_professional/profile'}
              className="text-sm  nav-link font-montserrat-Regular text-white ml-2 ">
              Account
            </a>

            <a
              style={{ justifyContent: 'center', alignSelf: 'center' }}
              className="text-sm  nav-link font-montserrat-Regular text-white ml-4 "
              onClick={() => logout()}>
              Logout
            </a>
          </Nav>
        </div>

        {openMenu && <div className="overlay-block full-screen"></div>}

        <div id="mySidenav" className={`sidenav ${openMenu ? 'open-nav' : 'close-nav'}`}>
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={() => props.changeMenuState(false)}>
            &times;
          </a>

          <NavLink onClick={() => props.changeMenuState(false)} to="/service_professional/bookings">
            <FontAwesomeIcon size="sm" icon={faBookmark} />

            <span className="ml-2 text-lg">Bookings</span>
          </NavLink>

          <NavLink onClick={() => props.changeMenuState(false)} to="/payments">
            <FontAwesomeIcon size="sm" icon={faMoneyBillAlt} />

            <span className="ml-2 text-lg">Payment History</span>
          </NavLink>

          <NavLink
            onClick={() => props.changeMenuState(false)}
            to="/service_professional/availability">
            <FontAwesomeIcon size="sm" icon={faCalendar} />

            <span className="ml-2 text-lg">Calendar</span>
          </NavLink>

          <NavLink onClick={() => props.changeMenuState(false)} to="/service_professional/card">
            <FontAwesomeIcon size="sm" icon={faCreditCard} />

            <span className="ml-2 text-lg">Connect Account</span>
          </NavLink>
        </div>
      </div>
    </Navbar>
  );
}

export default enhancer(Header);
