import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faMoneyBillAlt,
  faCalendar,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  openMenu: state.User.current.get('openMenu'),
});

let enhancer = connect(
  connectState,
  connectProps
);

function Header(props) {
  const toggle = () => {
    const menu = document.getElementById('menu');
    menu.classList.toggle('hidden');
  };

  return (
    <nav class="flex items-center justify-between flex-wrap bg-primary-color p-3 px-4">
      <div class="flex items-center flex-shrink-0 text-white mr-20">
        <span class="font-semibold text-xl font-montserrat-bold tracking-widest text-white">
          1V1 Athletics
        </span>
      </div>
      <div id="menuBtn" class="block md:hidden">
        <button
          onClick={() => toggle()}
          class="flex items-center px-3 py-2 border rounded text-white border-black-400 hover:text-gray-300 hover:border-gray-300">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div id="menu" class="w-full block flex-grow items-center lg:flex lg:items-center lg:w-auto">
        <div class="text-sm lg:flex-grow">
          <a
            href="#responsive-header"
            class="block mr-20 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
            HOME
          </a>
          <a
            href="#responsive-header"
            class="block mr-20 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
            ABOUT US
          </a>
          <a
            href="/client/profile"
            class="block mr-20 lg:inline-block lg:mt-0 text-white hover:text-gray-400s">
            DISCOVER
          </a>
        </div>
        <div>
          <a
            href="#"
            class="inline-block text-sm px-4 py-2 mx-2 leading-none border rounded text-black bg-cus-gray border-black hover:text-gray-500 hover:bg-white lg:mt-0 shadow-md">
            LOG IN
          </a>
          <a
            href="#"
            class="inline-block text-sm px-4 py-2 leading-none border rounded text-black bg-secondary-color border-black hover:text-gray-500 hover:bg-white lg:mt-0 shadow-md">
            SIGN UP
          </a>
        </div>
      </div>
    </nav>
  );
}

export default enhancer(Header);
