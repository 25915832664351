import React from 'react';
import { useHistory } from 'react-router-dom';

function App() {
  const history = useHistory();
  return (
    <div className="bg-home">
      <div className="row justify-between p-8 items-center">
        <h4 className="text-white text-sm">1v1 Athletics</h4>

        <div className="row items-center">
          <h4 className="text-white text-sm">Sign in as</h4>

          <button
            onClick={() => history.push('/athlete/login')}
            class="bg-indigo-100 hover:bg-yellow-400  font-normal py-1 px-4 border border-blue-700 ml-2 rounded text-sm">
            {'Athlete'}
          </button>

          <button
            onClick={() => history.push('/client/home')}
            class="bg-indigo-100 hover:bg-yellow-400  font-normal py-1 px-4 border border-blue-700 ml-2 mr-4 text-sm rounded">
            {'Client'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
