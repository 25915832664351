import React, { Component } from 'react';
import { BgSignup, BgSignupBusiness } from '../../../assets/img';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';
import { Loader } from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCalendar, faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import moment from 'moment';

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  booking: state.Bookings.booking.get('booking'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(
  connectState,
  connectProps
);

class BookingShow extends Component {
  componentDidMount() {
    let {
      getSingleBooking,
      match: { params },
    } = this.props;

    getSingleBooking(params.id);
  }

  render() {
    let { booking, updateStatus, loader } = this.props;

    let total = 0;

    booking &&
      booking.attributes.booked_services.data.map(service => {
        total += parseFloat(service.attributes.provided_service.data.attributes.price);
        return total;
      });

    return (
      <div className="gray-bg-color">
        {!loader ? (
          <div className="container min-h-screen py-12 ">
            <div className="row pb-3 justify-between">
              <h2 className="text-xl">Booking# {booking && booking.id}</h2>
            </div>

            <div className="row justify-content-center md:flex flex-col md:flex-row">
              <div className="col-md-9">
                <div>
                  <div className="row md:mr-2">
                    <table class="table-fixed bg-white w-full">
                      <thead className="border-b-2">
                        <tr>
                          <th class="pl-4 py-2">
                            <span className="text-gray-500 text-xs font-normal">Date: </span>

                            <span className="text-xs font-semibold">
                              {moment(
                                booking && booking.attributes.client.data.attributes.booking_date
                              ).format('ll')}
                            </span>
                          </th>

                          <th>
                            <span className="text-gray-500 text-xs font-normal">Status: </span>

                            <span className="text-xs font-semibold">
                              {booking && booking.attributes.status}
                            </span>
                          </th>

                          <th class="py-3">
                            <span className="text-gray-500 text-xs font-normal">Customer: </span>

                            <span className="text-xs font-semibold">
                              {booking && booking.attributes.client.data.attributes.first_name}{' '}
                              {booking && booking.attributes.client.data.attributes.last_name}{' '}
                            </span>

                            <span className="text-xs text-blue-500 font-normal">
                              {booking && booking.attributes.client.data.attributes.email}
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </table>

                    <div className="w-full bg-white">
                      <div
                        style={{ backgroundColor: '#F2F2F2' }}
                        className="flex flex-row justify-between items-center px-12 py-2 border">
                        <div className="flex flex-row">
                          <img src={BgSignupBusiness} className="rounded-full h-20 w-20" />

                          <div className="ml-4">
                            <h3 className="font-normal text-sm">
                              Order form{' '}
                              <span className="text-sm text-blue-500 font-normal">
                                {booking && booking.attributes.client.data.attributes.first_name}{' '}
                                {booking && booking.attributes.client.data.attributes.last_name}
                              </span>
                            </h3>

                            <h4 className="text-sm text-gray-600 pt-2">
                              Order# {booking && booking.id}
                            </h4>

                            <h4 className="text-sm text-gray-600 pt-1">
                              {booking && booking.attributes.booked_services.data.length} Services
                            </h4>
                          </div>
                        </div>

                        <div>
                          <h3 className="font-normal text-sm">${total}</h3>
                        </div>
                      </div>

                      {booking &&
                        booking.attributes.booked_services.data.map(service => (
                          <div className="flex flex-row justify-between items-center pl-24 pr-12 py-3 border-b-2">
                            <div className="flex flex-row items-center">
                              <img src={BgSignup} className="rounded-full h-16 w-16" />

                              <div className="ml-4">
                                <h3 className="font-normal text-gray-500">Service:</h3>

                                <h4 className="text-sm pt-2">
                                  {service.attributes.provided_service.data.attributes.name}
                                </h4>
                              </div>
                            </div>

                            <div>
                              <h3 className="font-normal text-sm text-gray-500">Price</h3>

                              <h4 className="text-sm pt-2">
                                ${service.attributes.provided_service.data.attributes.price}
                              </h4>
                            </div>
                          </div>
                        ))}

                      <div className="flex justify-end px-4 my-2 text-right text-sm">
                        <div className="flex flex-column">
                          <span className="font-normal text-gray-500">
                            Tax: <span className="font-normal text-black">0.00</span>
                          </span>

                          <span className="font-normal pt-2 text-gray-500">
                            Total: <span className="font-normal text-black">${total}</span>
                          </span>

                          {booking && booking.attributes.status === 'pending' && (
                            <div class="flex flex-row mt-4">
                              <button
                                onClick={() => updateStatus(booking.id, 'Accepted', true)}
                                class="bg-blue-500 hover:bg-blue-700 text-white font-normal py-2 px-4 border border-blue-500 rounded mx-2">
                                Accept
                              </button>

                              <button
                                onClick={() => updateStatus(booking.id, 'Rejected', true)}
                                class="bg-red-500 hover:bg-red-700 text-white font-normal py-2 px-4 border border-red-500 rounded mx-2">
                                Reject
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div class="row">
                  <div class="w-100 mt-12 md:mt-0">
                    <div className="flex shadow-2xl bg-white flex-column rounded-lg py-4 px-4 ">
                      <h3 className="font-semibold mb-2">Appointment details</h3>

                      <a className="py-2">
                        <FontAwesomeIcon size="xs" icon={faHome} />
                        <span className="ml-1 text-xs">
                          {' '}
                          {booking && booking.attributes.client.data.attributes.address}
                        </span>
                      </a>

                      <a className="py-2">
                        <FontAwesomeIcon size="xs" icon={faCalendar} />
                        <span className="ml-1 text-xs">
                          {' '}
                          {moment(
                            booking && booking.attributes.client.data.attributes.booking_date
                          ).format('ll')}
                        </span>
                      </a>

                      <a className="py-2">
                        <FontAwesomeIcon size="xs" icon={faPhone} />
                        <span className="ml-1 text-xs">
                          {' '}
                          {booking && booking.attributes.client.data.attributes.contact_number}{' '}
                        </span>
                      </a>

                      <a className="py-2">
                        <FontAwesomeIcon size="xs" icon={faEnvelope} />
                        <span className="ml-1 text-xs">
                          {' '}
                          {booking && booking.attributes.client.data.attributes.email}{' '}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                <div id="mapBox" className="row my-4 shadow-2xl">
                  <Map
                    google={this.props.google}
                    zoom={2}
                    style={{ width: '100%', height: 400, borderRadius: 10, position: 'relative' }}
                    initialCenter={{ lat: 47.444, lng: -122.176 }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex  container min-h-screen justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAU5JJtafm5w0Rcw6vJnQK8NgBuE8xhOK0',
})(enhancer(BookingShow));
