import { combineReducers } from 'redux';
import { Map } from 'immutable';

const metaDefault = {
  showHUD: false,
};

const userDefault = {
  currentUser: null,
  openMenu: false,
  userType: null,
};

function metaReducer(state = Map(metaDefault), action) {
  switch (action.type) {
    case 'SHOW_HUD':
      return state.set('showHUD', true);

    case 'HIDE_HUD':
      return state.set('showHUD', false);

    case 'SET_USER_TYPE':
      return state.set('userType', action.payload.value);

    default:
      return state;
  }
}

const currentUserReducer = (state = Map(userDefault), action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return state.set('currentUser', action.payload);

    case 'CHANGE_MENU_STATE':
      return state.set('openMenu', action.payload.value);

    default:
      return state;
  }
};

export default combineReducers({
  meta: metaReducer,
  current: currentUserReducer,
});
