import React, { useState } from 'react';
import { connect } from 'react-redux';
import './Hire.scss';
import DatePicker from 'react-datepicker';
import StarRatings from 'react-star-ratings';
import { BgSignupBusiness } from '../../../assets/img';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faSearch } from '@fortawesome/free-solid-svg-icons';

let connectProps = {};
let connectState = state => ({});

let enhancer = connect(connectState, connectProps);

function Hire(props) {
  return (
    <div className="bg-black min-h-screen">
      <div className="h-32 flex items-end" style={{ backgroundImage: `url(${BgSignupBusiness})` }}>
        <div className="bg-primary-color p-2 px-4 w-2/5 flex flex-row items-center">
          <img src={BgSignupBusiness} className="rounded-full h-20 w-20 mb-2" />
          <div className="flex flex-col ml-4">
            <p4 className="font-montserrat-bold text-white text-sm">Justin Fields</p4>
            <div>
              <FontAwesomeIcon icon={faMapMarkerAlt} size="xs" className="gray-icon mr-1" />
              <p6 className="text-white opacity-50 text-xs">East St. Louis, Mo.</p6>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary-color w-full flex flex-row justify-between py-2">
        <ul class="flex items-center ml-5">
          <li class="-mb-px p-1">
            <a class="inline-block py-2 px-4 text-white text-xs border-b-2" href="#">
              Home
            </a>
          </li>
          <li class="mr-1">
            <a class="inline-block py-2 px-4 text-white text-xs" href="#">
              All Trainings
            </a>
          </li>
          <li class="mr-1">
            <a class="inline-block py-2 px-4 text-white text-xs" href="#">
              Movies & Tickets
            </a>
          </li>
        </ul>
        <div class="bg-primary-color border flex flex-row  w-1/5 px-2 shadow rounded-md items-center mr-5">
          <input
            class="bg-primary-color border-none focus:outline-none text-xs appearance-none w-full py-2 px-3 text-gray-500"
            type="search"
            onChange={e => {}}
            placeholder="Search"
          />
          <FontAwesomeIcon icon={faSearch} className="gray-icon" size="xs" />
        </div>
      </div>

      <div class="flex py-4 flex-col md:flex-row px-4">
        <div class="w-full md:w-9/12 flex flex-col items-center">
          <div className="flex flex-row">
            {['a', 'b', 'c'].map(item => (
              <div class="w-1/4 overflow-hidden bg-primary-color mr-3">
                <img class="w-full" src={BgSignupBusiness} />
                <div class="p-2 bg-primary-color">
                  <p class="text-white text-xs card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia,
                    nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                  </p>
                </div>
                <div className="flex flex-row justify-between p-2">
                  <p class="text-secondary-color text-sm">$25</p>
                  <div>
                    <StarRatings
                      rating={5}
                      starRatedColor="#8A8604"
                      numberOfStars={6}
                      name="rating"
                      starDimension="10px"
                      starSpacing="1px"
                    />
                    <p4 className="text-xs text-secondary-color ml-1">(21)</p4>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-5">
            <p2 className="text-gray-500 text-lg">Trainings</p2>
            <div className="flex flex-row mt-4">
              {['a', 'b', 'c', 'd'].map(item => (
                <div class="w-1/5 overflow-hidden bg-primary-color mr-3">
                  <img class="w-full" src={BgSignupBusiness} />
                  <div class="p-2 bg-primary-color">
                    <p class="text-gray-600 text-xs card-desc">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia,
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div class="w-full md:w-2/6 bg-primary-color md:ml-3 p-3 rounded-md flex flex-col">
          <div>
            <div className="flex justify-between mb-2">
              <p4 className="text-white text-xs">Trainings starting from: </p4>
              <p4 className="text-white text-sm">$65</p4>
            </div>

            <div>
              {['a', 'b'].map(item => (
                <div className="border-b border-gray-600 py-3 flex flex-row justify-between">
                  <div className="flex flex-col">
                    <span class="ml-2 text-white text-sm">Sun, 26 Jul</span>
                    <span class="ml-2 text-white text-sm time-text">9:00 Am - 11:00 Am</span>
                  </div>
                  <button
                    onClick={() => {}}
                    class="rounded-md bg-cus-yellow text-black text-xs py-1 px-6 hover:bg-yellow-500 font-normal my-2 md:my-0">
                    Select
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div>
            <button
              onClick={() => {}}
              class="hire-button w-full mt-5 py-2 hover:bg-white hover:text-black rounded-md border-cus-yellow text-xs px-6 font-normal my-2 md:my-0">
              View more dates
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhancer(Hire);
