import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import './card.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { UserActions } from '../../../store/actions';
import { connect } from 'react-redux';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({});

let enhancer = connect(
  connectState,
  connectProps
);

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: 'white',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({ label, id, type, placeholder, required, autoComplete, value, onChange }) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>

    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}>
    {processing ? 'Processing...' : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />

      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const inititalBillingDetails = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  dob: new Date(),
};

const CheckoutForm = ({ props }) => {
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState(inititalBillingDetails);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error || !cardComplete) {
      elements.getElement('card').focus();
      return;
    }

    const payload = {
      service_professional: {
        first_name: billingDetails.firstName,
        last_name: billingDetails.lastName,
        dob: moment(billingDetails.dob).format('YYYY-MM-DD'),
        phone: billingDetails.phone,
        address: billingDetails.address,
        account_number: billingDetails.accountNumber,
        routing_number: billingDetails.routingNumber,
        ssn_last_4: billingDetails.ssn,
      },
    };

    setProcessing(true);

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    setProcessing(false);

    // console.log('payment response', paymentMethod);

    // await props.createAccount(payload).then(data => {
    //   setProcessing(false);
    //   if (data.data) {
    //   } else reset();
    // });

    // const result = await stripe.confirmCardPayment(
    //   'pi_1H7G0yB6so7PvqSAqyyTrgSY_secret_tXB8pDWmfFFTDEoVDdTGRFoLM',
    //   {
    //     payment_method: {
    //       card: elements.getElement(CardElement),
    //       billing_details: {
    //         name: 'Basit Elahi',
    //       },
    //     },
    //   }
    // );

    // if (result.error) {
    //   console.log(result.error.message);
    // } else {
    //   if (result.paymentIntent.status === 'succeeded') {
    //     console.log('success');
    //   }
    // }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails(inititalBillingDetails);
  };

  return (
    <div className="bg-white mb-12 p-4 shadow">
      <div className="row justify-end">
        <a href="https://connect.stripe.com/express/oauth/authorize?client_id=ca_32D88BD1qLklliziD7gYQvctJIhWBSQ7&state={STATE_VALUE}&suggested_capabilities[]=transfers&stripe_user[email]=user@example.com">
          <span className="stripe-button">Connect with Stripe</span>
        </a>
      </div>

      <form className="Form" onSubmit={handleSubmit}>
        <h3 style={{ marginLeft: '15px' }} className="py-2 font-bold">
          Account details
        </h3>

        <fieldset className="FormGroup" id="fieldSet">
          <Field
            label="First Name"
            id="name"
            type="text"
            placeholder="First Name"
            required
            autoComplete="name"
            value={billingDetails.firstName}
            onChange={e => {
              setBillingDetails({ ...billingDetails, firstName: e.target.value });
            }}
          />

          <Field
            label="Last Name"
            id="name"
            type="text"
            placeholder="Last Name"
            required
            autoComplete="name"
            value={billingDetails.lastName}
            onChange={e => {
              setBillingDetails({ ...billingDetails, lastName: e.target.value });
            }}
          />

          <Field
            label="Email"
            id="email"
            type="email"
            placeholder="xyz@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={e => {
              setBillingDetails({ ...billingDetails, email: e.target.value });
            }}
          />

          <Field
            label="Phone"
            id="phone"
            type="phone"
            placeholder="+1 000 000 0000"
            required
            autoComplete="phone"
            value={billingDetails.phone}
            onChange={e => {
              setBillingDetails({ ...billingDetails, phone: e.target.value });
            }}
          />

          <Field
            label="Address"
            id="address"
            type="address"
            placeholder="Sector 11 NYC"
            required
            autoComplete="address"
            value={billingDetails.address}
            onChange={e => {
              setBillingDetails({ ...billingDetails, address: e.target.value });
            }}
          />

          <Field
            label="SSN"
            id="ssn"
            type="phone"
            placeholder="Last 4 digits"
            required
            autoComplete="ssn"
            value={billingDetails.ssn}
            onChange={e => {
              setBillingDetails({ ...billingDetails, ssn: e.target.value });
            }}
          />

          <div className="FormRow">
            <label className="FormRowLabel">DOB</label>

            <div className="w-full">
              <DatePicker
                className="bg-transparent text-white text-sm w-full py-2"
                selected={billingDetails.dob}
                onChange={date => setBillingDetails({ ...billingDetails, dob: date })}
              />
            </div>
          </div>

          <Field
            label="Account Number"
            id="accountNumber"
            type="tel"
            placeholder="122105155"
            required
            autoComplete="tel"
            value={billingDetails.accountNumber}
            onChange={e => {
              setBillingDetails({ ...billingDetails, accountNumber: e.target.value });
            }}
          />

          <Field
            label="Routing Number"
            id="phone"
            type="tel"
            placeholder="122105155"
            required
            autoComplete="tel"
            value={billingDetails.routingNumber}
            onChange={e => {
              setBillingDetails({ ...billingDetails, routingNumber: e.target.value });
            }}
          />

          <CardField
            onChange={e => {
              console.log('e.error', e.error);
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </fieldset>

        {error && <ErrorMessage>{error.message}</ErrorMessage>}

        <SubmitButton processing={processing} error={error} disabled={!stripe}>
          Submit
        </SubmitButton>
      </form>
    </div>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const stripePromise = loadStripe(
  'pk_test_51H5BHaB6so7PvqSAAjw9NBxJImgwwSFLCQWsvxCKIdKwJb2p0NtzoczD8OdFFgIZ2T9apYkSRK91eRZRXPrpR7S600s9RpkJ7m'
);

const AddCard = props => {
  return (
    <div className="bg-white row justify-center mt-8" fluid>
      <div className="AppWrapper">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm props={props} />
        </Elements>
      </div>
    </div>
  );
};

export default enhancer(AddCard);
