import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import LoginForm from '../../Shared/LoginForm';

let connectProps = {
  ...UserActions,
};

let enhancer = connect(
  null,
  connectProps
);

function Signin(props) {
  // const submitSignin = async e => {
  //   e.preventDefault();
  //   setLoader(true);
  //   await props.signIn({ email, password });
  //   setLoader(false);
  // };

  let history = useHistory();
  return (
    <div>
      <LoginForm
        loginParent={() => {
          props.setUserType('client');
        }}
        signupParent={() => {
          alert('Design first');
        }}
      />
    </div>
  );
}

export default enhancer(Signin);
