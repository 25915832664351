import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const format = 'h:mm a';
const now = moment().hour(0).minute(0);
const days = [
  { day: 'Mon', checked: false, full: 'monday' },
  { day: 'Tue', checked: false, full: 'tuesday' },
  { day: 'Wed', checked: false, full: 'wednesday' },
  { day: 'Thur', checked: false, full: 'thursday' },
  { day: 'Fri', checked: false, full: 'friday' },
  { day: 'Sat', checked: false, full: 'saturday' },
  { day: 'Sun', checked: false, full: 'sunday' },
];

const RepeatValue = props => {
  return (
    <li>
      <a
        onClick={() => {
          props.onClick();
        }}
        class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
        href="#">
        {props.value}
      </a>
    </li>
  );
};

function AddAvailability(props) {
  const min = new Date(props.selectedDay);
  const [show, setShow] = useState(false);
  const [repeat, setRepeat] = useState('Weekly');
  const [repeatCount, setRepeatCount] = useState(1);
  const [weekDays, setWeekDays] = useState(days);
  const [date, setDate] = useState(new Date(min.setDate(min.getDate() + 1)));
  const [start_time, setStart] = useState(null);
  const [end_time, setEnd] = useState(null);
  const [neverStop, setStop] = useState(false);
  const [countSelect, setCountSelect] = useState(false);

  //states to show error
  const [timeError, setTimeError] = useState(false);
  const [daysError, setDaysError] = useState(false);

  const handleDaySelection = passed => {
    daysError && setDaysError(false);
    const mapped = days.map(item =>
      item.day === passed.day ? { ...item, checked: !item.checked } : item
    );
    setWeekDays(mapped);
  };

  const renderDays = () => {
    return weekDays.map(item => {
      return (
        <label class="inline-flex items-center mt-3 ml-2">
          <input
            type="checkbox"
            class="form-checkbox h-5 w-5"
            checked={item.checked}
            onClick={() => {
              handleDaySelection(item);
            }}
          />
          <span class="ml-2 text-gray-700">{item.day}</span>
        </label>
      );
    });
  };

  const handleChange = date => {
    setDate(date);
  };

  const setData = () => {
    setStart(moment(props.info.attributes.from_time).utc());
    setEnd(moment(props.info.attributes.to_time).utc());
    setDate(new Date(moment(props.info.attributes.repetition_until).format('YYYY-MM-DD')));
    setRepeat(props.info.attributes.repetition_frequency);
    setRepeatCount(props.info.attributes.repetition_count);
    const passedDays = props.info.attributes.days;
    let pDays = days.map(item => {
      passedDays.includes(item.full) ? (item.checked = true) : (item.checked = false);
      return item;
    });
    setWeekDays(pDays);
  };

  useEffect(() => {
    if (props.editEvent) {
      setData();
    }

    const timer = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const switchController = day => {
    switch (day) {
      case 'Mon':
        return 'monday';

      case 'Tue':
        return 'tuesday';

      case 'Wed':
        return 'wednesday';

      case 'Thur':
        return 'thursday';

      case 'Fri':
        return 'friday';

      case 'Sat':
        return 'saturday';

      case 'Sun':
        return 'sunday';

      default:
        return day;
    }
  };

  const fillData = () => {
    const selectedDays = weekDays
      .filter(item => {
        if (item.checked) {
          return item;
        }
      })
      .map(item => {
        return switchController(item.day);
      });

    const endDate = moment(date).format('YYYY-MM-DD');

    if (start_time === null || endTime === null) {
      setTimeError(true);
      return;
    }

    const frequency = repeat.toLowerCase();

    if (selectedDays.length === 0 && frequency !== 'daily') {
      setDaysError(true);
      return;
    }

    if (!countSelect && !neverStop) {
      alert('Select one from end section');
      return;
    }

    // props.editEvent
    //   ? props.update({
    //       start_time,
    //       end_time,
    //       selectedDays,
    //       frequency,
    //       repeatCount,
    //       endDate,
    //       id: props.info.id,
    //       startDate: moment(props.info.attributes.repetition_from).format('YYYY-MM-DD'),
    //     })
    //   : props.save({ start_time, end_time, selectedDays, frequency, repeatCount, endDate });

    props.save({ start_time, end_time, selectedDays, frequency, repeatCount, endDate, neverStop });
  };

  const startTime = data => {
    timeError && setTimeError(false);
    setStart(moment(data).format('HH:mm:ss'));
  };

  const endTime = data => {
    timeError && setTimeError(false);
    setEnd(moment(data).format('HH:mm:ss'));
  };

  let sDate = '';

  if (props.editEvent) {
    sDate = moment(
      props.info && props.info.attributes && props.info.attributes.repetition_from
    ).format('YYYY-MM-DD');
  }

  return (
    <Modal size="md" show={show} centered>
      <div className="flex-row flex bg-white rounded-md ">
        <div className="flex-col flex m-4">
          <h3>Add Availability</h3>

          <div class="border-b border-gray-600 mt-2" />

          <div className="row justify-between items-center mx-4 mt-4">
            <h3>{props.editEvent ? sDate : props.selectedDay}</h3>

            <TimePicker
              showSecond={false}
              defaultValue={start_time ? start_time : now}
              className="xxx"
              onChange={startTime}
              format={format}
              use12Hours
              inputReadOnly
            />

            <TimePicker
              showSecond={false}
              defaultValue={end_time ? end_time : now}
              className="xxx"
              onChange={endTime}
              format={format}
              use12Hours
              inputReadOnly
            />
          </div>

          <span className={`${timeError ? 'text-xs text-red-800 align-self-center' : 'd-none'}`}>
            Please set start and end time
          </span>

          <div class="border-b border-gray-600 mt-2" />

          <div className="row justify-between items-center m-2">
            <h3> Repeat</h3>

            <div class="ml-5">
              <div class="dropdown inline-block relative">
                <button class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
                  <span class="mr-1">{repeat}</span>

                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{' '}
                  </svg>
                </button>

                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                  <RepeatValue
                    value="Daily"
                    onClick={() => {
                      setWeekDays(days);
                      setRepeat('Daily');
                    }}
                  />
                  <RepeatValue value="Weekly" onClick={() => setRepeat('Weekly')} />
                </ul>
              </div>
            </div>
          </div>

          <div
            class={`flex-column items-center justify-center ${
              repeat === 'Weekly' ? 'flex' : 'disable'
            }`}>
            <div>{renderDays()}</div>

            <span className={`${daysError ? 'text-xs text-red-800' : 'd-none'}`}>
              Please select at least one day
            </span>
          </div>

          <div class="border-b border-gray-600 mt-2" />

          <div className="row justify-between  m-2 mr-5 mt-3">
            <h3>End</h3>

            <div className="flex flex-column">
              <label
                class={`inline-flex items-center my-1 ml-2 ${!countSelect ? 'flex' : 'disable'}`}>
                <input
                  type="checkbox"
                  class="form-checkbox h-5 w-5"
                  checked={neverStop}
                  onClick={() => {
                    setStop(!neverStop);
                  }}
                />

                <span class="ml-2 text-gray-700">Never</span>
              </label>

              <label
                class={`inline-flex items-center my-1 ml-2 ${!neverStop ? 'flex' : 'disable'}`}>
                <input
                  type="checkbox"
                  class="form-checkbox h-5 w-5"
                  checked={countSelect}
                  onClick={() => {
                    setCountSelect(!countSelect);
                  }}
                />

                <form className="mx-2">
                  <div class="w-12">
                    <input
                      class="form-control input-sm"
                      id="inputsm"
                      type="text"
                      maxLength={3}
                      onChange={e => {
                        setRepeatCount(e.target.value);
                      }}
                    />
                  </div>
                </form>

                <span class="text-gray-700">Repetitions</span>
              </label>

              <DatePicker
                className="border-2 shadow py-1 text-center rounded"
                selected={date}
                onChange={date => handleChange(date)}
                minDate={date}
              />
            </div>
          </div>

          <div className="row justify-center items-center  mr-5 mt-4">
            <button
              onClick={() => props.closeModal()}
              class="bg-white hover:bg-secondary text-blue-700 font-semibold hover:text-black py-2 px-4 border border-blue-500 rounded">
              Cancel
            </button>

            <button
              onClick={() => fillData()}
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 ml-2 rounded">
              {props.editEvent ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddAvailability;
