import { combineReducers } from 'redux';
import User from './User/reducers';
import Bookings from './Bookings/reducers';
import Calendar from './Availability/reducers';

const appReducer = combineReducers({
  User,
  Bookings,
  Calendar,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
