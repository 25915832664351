import api from '../api';
import qs from 'qs';
import { client } from '../index';
import { toast } from 'react-toastify';
import { showHUD, hideHUD } from '../User/actions';

export const getAvailabilities = month => async dispatch => {
  let payload = {
    month,
  };
  let paramsString = qs.stringify(payload);
  const { data } = await client().get(`${api.availabilities()}?${paramsString}`);
  dispatch({ type: 'SET_AVAILABILITIES', payload: { data: data.data } });
};

export const getCalendarEntries = month => async dispatch => {
  let payload = {
    month,
  };
  let paramsString = qs.stringify(payload);
  const { data } = await client().get(`${api.entries()}?${paramsString}`);
  dispatch({ type: 'SET_ENTRIES', payload: { data: data.data } });
  dispatch(hideHUD());
};

export const createAvailability = payload => async dispatch => {
  await client().post(api.availabilities(), payload);
};

export const deleteEvent = id => async dispatch => {
  await client().delete(`${api.availabilities()}/${id}`);
};

export const updateAvailability = (payload, id) => async dispatch => {
  await client().put(`${api.availabilities()}/${id}`, payload);
  toast.success('Updated successfully');
};

export const deleteEntry = id => async dispatch => {
  await client().delete(`${api.entries()}/${id}`);
  toast.success('Deleted successfully');
};
