import React, { Component, createRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createEventId } from './event-utils';
import { Modal } from 'react-bootstrap';
import { AddAvailability } from '../AddAvailibility';
import { connect } from 'react-redux';
import { CalendarActions, UserActions } from '../../../store/actions';
import moment from 'moment';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Loader } from '../Loader';

let connectProps = {
  ...CalendarActions,
  ...UserActions,
};

let connectState = state => ({
  availabilities: state.Calendar.calendar,
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

var clickEvent = null;

class Availability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEvents: [],
      showModal: false,
      addModal: false,
      selectedDay: '',
      selectInfo: null,
      events: [],
      editEvent: false,
      currentMonth: null,
      selectedType: 'dayGridMonth',
      deletePressed: false,
      current: null,
    };
    this.calendarRef = createRef();
  }

  componentDidMount() {
    this.props.showHUD();
    const {
      match: { params },
    } = this.props;
    if (params.month) {
      this.setState({ currentMonth: params.month });
    } else {
    }
  }

  getData = async currentMonth => {
    await this.props.getCalendarEntries(currentMonth);
    let data = this.props.availabilities.toJS().entries;

    const dates = data.map(item => {
      return {
        title: item.attributes.availability_title,
        start: moment(item.attributes.from_time).toISOString(),
        end: moment(item.attributes.to_time).toISOString(),
        extendedProps: item,
        color: item.attributes.booked ? '#cc0000' : '#B4CDCD',
      };
    });

    this.setState({ events: dates });
  };

  handleMonthChange = date => {
    let month = moment(date.startStr).format('YYYY-MM-DD');
    this.setState({ currentMonth: month, selectedType: date.view.type }, () => {
      this.props.history.push(`/service_professional/availability/${month}`);
    });
    this.getData(month);
  };

  closeModal = () => {
    this.setState({
      addModal: false,
    });
  };

  save = data => {
    let { editEvent, currentMonth } = this.state;
    const repetition_from = editEvent ? data.startDate : this.state.selectedDay;
    const from_time = data.start_time;
    const to_time = data.end_time;
    const repetition_frequency = data.frequency;
    const repetition_until = data.endDate;
    const repetition_count = data.repeatCount;
    const days = data.selectedDays;

    // if (!editEvent) {
    //   let calendarApi = this.state.selectInfo.view.calendar;
    //   calendarApi.unselect();

    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title: '',
    //     start: this.state.selectedDay,
    //     end: repetition_until,
    //     allDay: true,
    //   });
    // }

    const payload = {
      availability: {
        from_time,
        to_time,
        repetition_frequency,
        repetition_from,
        repetition_until,
        repetition_count,
        days,
        repetition_never_stop: data.neverStop,
      },
    };

    editEvent
      ? this.props.updateAvailability(payload, data.id)
      : this.props.createAvailability(payload);
    this.getData(currentMonth);
    this.closeModal();
  };

  renderDate = () => {
    const info = clickEvent && clickEvent.extendedProps;

    const start = moment(info.attributes && info.attributes.from_time).format('Do MMM YYYY');
    const end = moment(info.attributes && info.attributes.to_time).format('Do MMM YYYY');

    const sTime = moment(info.attributes && info.attributes.from_time).format('LT');
    const eTime = moment(info.attributes && info.attributes.to_time).format('LT');

    return (
      <div>
        <h2 className="font-normal pb-3 border-b-2 mx-2">
          Title: {info.attributes && info.attributes.availability_title}
        </h2>
        <div className="flex flex-column mx-2 mt-2 items-end">
          <h2 className="mb-6">{`${start === end ? start : start - end}`}</h2>

          <h2
            className={`mb-2  ${info.attributes.booked ? 'text-red-700' : 'text-indigo-300'}`}>{`${
            info.attributes.booked ? 'Booked' : 'Free slot'
          }`}</h2>

          <h2 className="mb-8">
            {sTime} - {eTime}
          </h2>
        </div>
      </div>
    );
  };

  deleteEvent = () => {
    this.setState({ showModal: false });
    this.state.clickEvent.remove();
    const eventData = this.state.clickEvent.extendedProps;
    this.props.deleteEvent(eventData.id);
    toast.success('Deleted successfully');
  };

  render() {
    let { showModal, events, editEvent, selectedType } = this.state;
    let { loader } = this.props;

    return (
      <div className="container">
        {!loader ? (
          <div>
            <div className="p-5">
              <FullCalendar
                ref={this.calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                initialView={selectedType}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                initialDate={this.state.currentMonth}
                showNonCurrentDates={false}
                datesSet={date => {
                  this.handleMonthChange(date);
                }}
                events={events}
                select={this.handleDateSelect}
                eventContent={this.renderEventContent} // custom render function
                eventClick={this.handleEventClick}
                eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
              />
            </div>

            {this.state.addModal && (
              <AddAvailability
                save={this.save}
                update={this.save}
                closeModal={() => this.closeModal()}
                selectedDay={editEvent ? '2020-07-09' : this.state.selectedDay}
                editEvent={this.state.editEvent}
                info={editEvent && clickEvent.extendedProps}
              />
            )}

            <Modal
              show={showModal}
              centered
              onHide={() => this.setState({ showModal: false })}
              size="sm">
              <div className="p-2">
                {clickEvent && this.renderDate()}

                <div className="flex flex-row">
                  {/* <button
                onClick={() => {
                  this.setState({ showModal: false, editEvent: true, addModal: true });
                }}
                className="hover:text-blue-700 font-normal py-2 px-1 mx-1 border-none focus:outline-none appearance-none">
                Edit
              </button> */}

                  <button
                    onClick={() => {
                      this.showPopup();
                    }}
                    className="hover:text-red-800 font-normal py-2 px-1 mx-1 border-none focus:outline-none appearance-none">
                    Delete
                  </button>
                </div>
              </div>
            </Modal>

            <SweetAlert
              show={this.state.deletePressed}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={this.deleteEntry}
              onCancel={() => {
                this.setState({ deletePressed: false });
              }}
              focusCancelBtn>
              You will not be able to recover!
            </SweetAlert>
          </div>
        ) : (
          <div className="flex  container min-h-screen justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    );
  }

  handleDateSelect = selectInfo => {
    this.setState({ editEvent: false }, () => {
      this.setState({
        selectedDay: selectInfo.startStr,
        selectInfo,
        addModal: true,
      });
    });
  };

  handleEventClick = ({ event, el, jsEvent, view }) => {
    clickEvent = event;
  };

  handleEvents = events => {
    this.setState({
      currentEvents: events,
    });
  };

  showPopup = () => {
    this.setState({ deletePressed: true });
  };

  deleteEntry = () => {
    const id = clickEvent.extendedProps.id;
    this.setState({ deletePressed: false, showModal: false }, async () => {
      await this.props.deleteEntry(id);
      this.getData(this.state.currentMonth);
    });
  };

  showDetails = () => {
    setTimeout(() => {
      this.setState({ showModal: true });
    }, 100);
  };

  renderEventContent = eventInfo => {
    let props = eventInfo.event.extendedProps;
    const sTime = moment(props.attributes && props.attributes.from_time).format('LT');
    const eTime = moment(props.attributes && props.attributes.to_time).format('LT');
    let { selectedType } = this.state;

    return (
      <div
        onClick={this.showDetails}
        style={{
          backgroundColor: props.attributes && props.attributes.booked ? '#cc0000' : '#B4CDCD',
        }}
        className="flex flex-row justify-between py-2 pl-1 rounded items-center">
        <small className={`${props.attributes.booked ? 'text-white' : 'text-black'}`}>
          {props.attributes && props.attributes.booked
            ? truncate(eventInfo.event.title)
            : 'Free slot'}
        </small>

        <small
          style={{ fontSize: selectedType === 'timeGridWeek' ? '9px' : '' }}
          className={`${
            props.attributes && props.attributes.booked ? 'text-white' : 'text-black'
          }`}>
          {sTime}-{eTime}
        </small>

        <button
          onClick={e => {
            e.stopPropagation();
            this.showPopup();
          }}
          class="bg-blue-500 hover:bg-red-700 text-white font-bold rounded text-red-700">
          <small className="px-1">X</small>
        </button>
      </div>
    );
  };
}

function truncate(str) {
  return str.length > 5 ? str.substring(0, 4) + '...' : str;
}

export default enhancer(Availability);
