export const cities = [
  { value: 'New York,New York', label: 'New York,New York' },
  { value: 'Los Angeles,California', label: 'Los Angeles,California' },
  { value: 'Chicago,Illinois', label: 'Chicago,Illinois' },
  { value: 'Houston,Texas', label: 'Houston,Texas' },
  { value: 'Philadelphia,Pennsylvania', label: 'Philadelphia,Pennsylvania' },
  { value: 'Phoenix,Arizona', label: 'Phoenix,Arizona' },
  { value: 'San Antonio,Texas', label: 'San Antonio,Texas' },
  { value: 'San Diego,California', label: 'San Diego,California' },
  { value: 'Dallas,Texas', label: 'Dallas,Texas' },
  { value: 'San Jose,California', label: 'San Jose,California' },
  { value: 'Austin,Texas', label: 'Austin,Texas' },
  { value: 'Indianapolis,Indiana', label: 'Indianapolis,Indiana' },
  { value: 'Jacksonville,Florida', label: 'Jacksonville,Florida' },
  { value: 'San Francisco,California', label: 'San Francisco,California' },
  { value: 'Columbus,Ohio', label: 'Columbus,Ohio' },
  { value: 'Charlotte,North Carolina', label: 'Charlotte,North Carolina' },
  { value: 'Fort Worth,Texas', label: 'Fort Worth,Texas' },
  { value: 'Detroit,Michigan', label: 'Detroit,Michigan' },
  { value: 'El Paso,Texas', label: 'El Paso,Texas' },
  { value: 'Memphis,Tennessee', label: 'Memphis,Tennessee' },
  { value: 'Seattle,Washington', label: 'Seattle,Washington' },
  { value: 'Denver,Colorado', label: 'Denver,Colorado' },
  { value: 'Washington,District of Columbia', label: 'Washington,District of Columbia' },
  { value: 'Boston,Massachusetts', label: 'Boston,Massachusetts' },
  { value: 'NashvilleDavidson,Tennessee', label: 'NashvilleDavidson,Tennessee' },
  { value: 'Baltimore,Maryland', label: 'Baltimore,Maryland' },
  { value: 'Oklahoma City,Oklahoma', label: 'Oklahoma City,Oklahoma' },
  { value: 'Louisville/Jefferson County,Kentucky', label: 'Louisville/Jefferson County,Kentucky' },
  { value: 'Portland,Oregon', label: 'Portland,Oregon' },
  { value: 'Las Vegas,Nevada', label: 'Las Vegas,Nevada' },
  { value: 'Milwaukee,Wisconsin', label: 'Milwaukee,Wisconsin' },
  { value: 'Albuquerque,New Mexico', label: 'Albuquerque,New Mexico' },
  { value: 'Tucson,Arizona', label: 'Tucson,Arizona' },
  { value: 'Fresno,California', label: 'Fresno,California' },
  { value: 'Sacramento,California', label: 'Sacramento,California' },
  { value: 'Long Beach,California', label: 'Long Beach,California' },
  { value: 'Kansas City,Missouri', label: 'Kansas City,Missouri' },
  { value: 'Mesa,Arizona', label: 'Mesa,Arizona' },
  { value: 'Virginia Beach,Virginia', label: 'Virginia Beach,Virginia' },
  { value: 'Atlanta,Georgia', label: 'Atlanta,Georgia' },
  { value: 'Colorado Springs,Colorado', label: 'Colorado Springs,Colorado' },
  { value: 'Omaha,Nebraska', label: 'Omaha,Nebraska' },
  { value: 'Raleigh,North Carolina', label: 'Raleigh,North Carolina' },
  { value: 'Miami,Florida', label: 'Miami,Florida' },
  { value: 'Oakland,California', label: 'Oakland,California' },
  { value: 'Minneapolis,Minnesota', label: 'Minneapolis,Minnesota' },
  { value: 'Tulsa,Oklahoma', label: 'Tulsa,Oklahoma' },
  { value: 'Cleveland,Ohio', label: 'Cleveland,Ohio' },
  { value: 'Wichita,Kansas', label: 'Wichita,Kansas' },
  { value: 'Arlington,Texas', label: 'Arlington,Texas' },
  { value: 'New Orleans,Louisiana', label: 'New Orleans,Louisiana' },
  { value: 'Bakersfield,California', label: 'Bakersfield,California' },
  { value: 'Tampa,Florida', label: 'Tampa,Florida' },
  { value: 'Honolulu,Hawaii', label: 'Honolulu,Hawaii' },
  { value: 'Aurora,Colorado', label: 'Aurora,Colorado' },
  { value: 'Anaheim,California', label: 'Anaheim,California' },
  { value: 'Santa Ana,California', label: 'Santa Ana,California' },
  { value: 'St Louis,Missouri', label: 'St Louis,Missouri' },
  { value: 'Riverside,California', label: 'Riverside,California' },
  { value: 'Corpus Christi,Texas', label: 'Corpus Christi,Texas' },
  { value: 'LexingtonFayette,Kentucky', label: 'LexingtonFayette,Kentucky' },
  { value: 'Pittsburgh,Pennsylvania', label: 'Pittsburgh,Pennsylvania' },
  { value: 'Anchorage,Alaska', label: 'Anchorage,Alaska' },
  { value: 'Stockton,California', label: 'Stockton,California' },
  { value: 'Cincinnati,Ohio', label: 'Cincinnati,Ohio' },
  { value: 'St Paul,Minnesota', label: 'St Paul,Minnesota' },
  { value: 'Toledo,Ohio', label: 'Toledo,Ohio' },
  { value: 'Greensboro,North Carolina', label: 'Greensboro,North Carolina' },
  { value: 'Newark,New Jersey', label: 'Newark,New Jersey' },
  { value: 'Plano,Texas', label: 'Plano,Texas' },
  { value: 'Henderson,Nevada', label: 'Henderson,Nevada' },
  { value: 'Lincoln,Nebraska', label: 'Lincoln,Nebraska' },
  { value: 'Buffalo,New York', label: 'Buffalo,New York' },
  { value: 'Jersey City,New Jersey', label: 'Jersey City,New Jersey' },
  { value: 'Chula Vista,California', label: 'Chula Vista,California' },
  { value: 'Fort Wayne,Indiana', label: 'Fort Wayne,Indiana' },
  { value: 'Orlando,Florida', label: 'Orlando,Florida' },
  { value: 'St Petersburg,Florida', label: 'St Petersburg,Florida' },
  { value: 'Chandler,Arizona', label: 'Chandler,Arizona' },
  { value: 'Laredo,Texas', label: 'Laredo,Texas' },
  { value: 'Norfolk,Virginia', label: 'Norfolk,Virginia' },
  { value: 'Durham,North Carolina', label: 'Durham,North Carolina' },
  { value: 'Madison,Wisconsin', label: 'Madison,Wisconsin' },
  { value: 'Lubbock,Texas', label: 'Lubbock,Texas' },
  { value: 'Irvine,California', label: 'Irvine,California' },
  { value: 'WinstonSalem,North Carolina', label: 'WinstonSalem,North Carolina' },
  { value: 'Glendale,Arizona', label: 'Glendale,Arizona' },
  { value: 'Garland,Texas', label: 'Garland,Texas' },
  { value: 'Hialeah,Florida', label: 'Hialeah,Florida' },
  { value: 'Reno,Nevada', label: 'Reno,Nevada' },
  { value: 'Chesapeake,Virginia', label: 'Chesapeake,Virginia' },
  { value: 'Gilbert,Arizona', label: 'Gilbert,Arizona' },
  { value: 'Baton Rouge,Louisiana', label: 'Baton Rouge,Louisiana' },
  { value: 'Irving,Texas', label: 'Irving,Texas' },
  { value: 'Scottsdale,Arizona', label: 'Scottsdale,Arizona' },
  { value: 'North Las Vegas,Nevada', label: 'North Las Vegas,Nevada' },
  { value: 'Fremont,California', label: 'Fremont,California' },
  { value: 'Boise City,Idaho', label: 'Boise City,Idaho' },
  { value: 'Richmond,Virginia', label: 'Richmond,Virginia' },
  { value: 'San Bernardino,California', label: 'San Bernardino,California' },
  { value: 'Birmingham,Alabama', label: 'Birmingham,Alabama' },
  { value: 'Spokane,Washington', label: 'Spokane,Washington' },
  { value: 'Rochester,New York', label: 'Rochester,New York' },
  { value: 'Des Moines,Iowa', label: 'Des Moines,Iowa' },
  { value: 'Modesto,California', label: 'Modesto,California' },
  { value: 'Fayetteville,North Carolina', label: 'Fayetteville,North Carolina' },
  { value: 'Tacoma,Washington', label: 'Tacoma,Washington' },
  { value: 'Oxnard,California', label: 'Oxnard,California' },
  { value: 'Fontana,California', label: 'Fontana,California' },
  { value: 'Columbus,Georgia', label: 'Columbus,Georgia' },
  { value: 'Montgomery,Alabama', label: 'Montgomery,Alabama' },
  { value: 'Moreno Valley,California', label: 'Moreno Valley,California' },
  { value: 'Shreveport,Louisiana', label: 'Shreveport,Louisiana' },
  { value: 'Aurora,Illinois', label: 'Aurora,Illinois' },
  { value: 'Yonkers,New York', label: 'Yonkers,New York' },
  { value: 'Akron,Ohio', label: 'Akron,Ohio' },
  { value: 'Huntington Beach,California', label: 'Huntington Beach,California' },
  { value: 'Little Rock,Arkansas', label: 'Little Rock,Arkansas' },
  { value: 'AugustaRichmond County,Georgia', label: 'AugustaRichmond County,Georgia' },
  { value: 'Amarillo,Texas', label: 'Amarillo,Texas' },
  { value: 'Glendale,California', label: 'Glendale,California' },
  { value: 'Mobile,Alabama', label: 'Mobile,Alabama' },
  { value: 'Grand Rapids,Michigan', label: 'Grand Rapids,Michigan' },
  { value: 'Salt Lake City,Utah', label: 'Salt Lake City,Utah' },
  { value: 'Tallahassee,Florida', label: 'Tallahassee,Florida' },
  { value: 'Huntsville,Alabama', label: 'Huntsville,Alabama' },
  { value: 'Grand Prairie,Texas', label: 'Grand Prairie,Texas' },
  { value: 'Knoxville,Tennessee', label: 'Knoxville,Tennessee' },
  { value: 'Worcester,Massachusetts', label: 'Worcester,Massachusetts' },
  { value: 'Newport News,Virginia', label: 'Newport News,Virginia' },
  { value: 'Brownsville,Texas', label: 'Brownsville,Texas' },
  { value: 'Overland Park,Kansas', label: 'Overland Park,Kansas' },
  { value: 'Santa Clarita,California', label: 'Santa Clarita,California' },
  { value: 'Providence,Rhode Island', label: 'Providence,Rhode Island' },
  { value: 'Garden Grove,California', label: 'Garden Grove,California' },
  { value: 'Chattanooga,Tennessee', label: 'Chattanooga,Tennessee' },
  { value: 'Oceanside,California', label: 'Oceanside,California' },
  { value: 'Jackson,Mississippi', label: 'Jackson,Mississippi' },
  { value: 'Fort Lauderdale,Florida', label: 'Fort Lauderdale,Florida' },
  { value: 'Santa Rosa,California', label: 'Santa Rosa,California' },
  { value: 'Rancho Cucamonga,California', label: 'Rancho Cucamonga,California' },
  { value: 'Port St Lucie,Florida', label: 'Port St Lucie,Florida' },
  { value: 'Tempe,Arizona', label: 'Tempe,Arizona' },
  { value: 'Ontario,California', label: 'Ontario,California' },
  { value: 'Vancouver,Washington', label: 'Vancouver,Washington' },
  { value: 'Cape Coral,Florida', label: 'Cape Coral,Florida' },
  { value: 'Sioux Falls,South Dakota', label: 'Sioux Falls,South Dakota' },
  { value: 'Springfield,Missouri', label: 'Springfield,Missouri' },
  { value: 'Peoria,Arizona', label: 'Peoria,Arizona' },
  { value: 'Pembroke Pines,Florida', label: 'Pembroke Pines,Florida' },
  { value: 'Elk Grove,California', label: 'Elk Grove,California' },
  { value: 'Salem,Oregon', label: 'Salem,Oregon' },
  { value: 'Lancaster,California', label: 'Lancaster,California' },
  { value: 'Corona,California', label: 'Corona,California' },
  { value: 'Eugene,Oregon', label: 'Eugene,Oregon' },
  { value: 'Palmdale,California', label: 'Palmdale,California' },
  { value: 'Salinas,California', label: 'Salinas,California' },
  { value: 'Springfield,Massachusetts', label: 'Springfield,Massachusetts' },
  { value: 'Pasadena,Texas', label: 'Pasadena,Texas' },
  { value: 'Fort Collins,Colorado', label: 'Fort Collins,Colorado' },
  { value: 'Hayward,California', label: 'Hayward,California' },
  { value: 'Pomona,California', label: 'Pomona,California' },
  { value: 'Cary,North Carolina', label: 'Cary,North Carolina' },
  { value: 'Rockford,Illinois', label: 'Rockford,Illinois' },
  { value: 'Alexandria,Virginia', label: 'Alexandria,Virginia' },
  { value: 'Escondido,California', label: 'Escondido,California' },
  { value: 'McKinney,Texas,', label: 'McKinney,Texas,' },
  { value: 'Kansas City,Kansas', label: 'Kansas City,Kansas' },
  { value: 'Joliet,Illinois', label: 'Joliet,Illinois' },
  { value: 'Sunnyvale,California', label: 'Sunnyvale,California' },
  { value: 'Torrance,California', label: 'Torrance,California' },
  { value: 'Bridgeport,Connecticut', label: 'Bridgeport,Connecticut' },
  { value: 'Lakewood,Colorado', label: 'Lakewood,Colorado' },
  { value: 'Hollywood,Florida', label: 'Hollywood,Florida' },
  { value: 'Paterson,New Jersey', label: 'Paterson,New Jersey' },
  { value: 'Naperville,Illinois', label: 'Naperville,Illinois' },
  { value: 'Syracuse,New York', label: 'Syracuse,New York' },
  { value: 'Mesquite,Texas', label: 'Mesquite,Texas' },
  { value: 'Dayton,Ohio', label: 'Dayton,Ohio' },
  { value: 'Savannah,Georgia', label: 'Savannah,Georgia' },
  { value: 'Clarksville,Tennessee', label: 'Clarksville,Tennessee' },
  { value: 'Orange,California', label: 'Orange,California' },
  { value: 'Pasadena,California', label: 'Pasadena,California' },
  { value: 'Fullerton,California', label: 'Fullerton,California' },
  { value: 'Killeen,Texas', label: 'Killeen,Texas' },
  { value: 'Frisco,Texas', label: 'Frisco,Texas' },
  { value: 'Hampton,Virginia', label: 'Hampton,Virginia' },
  { value: 'McAllen,Texas', label: 'McAllen,Texas' },
  { value: 'Warren,Michigan', label: 'Warren,Michigan' },
  { value: 'Bellevue,Washington', label: 'Bellevue,Washington' },
  { value: 'West Valley City,Utah', label: 'West Valley City,Utah' },
  { value: 'Columbia,South Carolina', label: 'Columbia,South Carolina' },
  { value: 'Olathe,Kansas', label: 'Olathe,Kansas' },
  { value: 'Sterling Heights,Michigan', label: 'Sterling Heights,Michigan' },
  { value: 'New Haven,Connecticut', label: 'New Haven,Connecticut' },
  { value: 'Miramar,Florida', label: 'Miramar,Florida' },
  { value: 'Waco,Texas', label: 'Waco,Texas' },
  { value: 'Thousand Oaks,California', label: 'Thousand Oaks,California' },
  { value: 'Cedar Rapids,Iowa', label: 'Cedar Rapids,Iowa' },
  { value: 'Charleston,South Carolina', label: 'Charleston,South Carolina' },
  { value: 'Visalia,California', label: 'Visalia,California' },
  { value: 'Topeka,Kansas', label: 'Topeka,Kansas' },
  { value: 'Elizabeth,New Jersey', label: 'Elizabeth,New Jersey' },
  { value: 'Gainesville,Florida', label: 'Gainesville,Florida' },
  { value: 'Thornton,Colorado', label: 'Thornton,Colorado' },
  { value: 'Roseville,California', label: 'Roseville,California' },
  { value: 'Carrollton,Texas', label: 'Carrollton,Texas' },
  { value: 'Coral Springs,Florida', label: 'Coral Springs,Florida' },
  { value: 'Stamford,Connecticut', label: 'Stamford,Connecticut' },
  { value: 'Simi Valley,California', label: 'Simi Valley,California' },
  { value: 'Concord,California', label: 'Concord,California' },
  { value: 'Hartford,Connecticut', label: 'Hartford,Connecticut' },
  { value: 'Kent,Washington', label: 'Kent,Washington' },
  { value: 'Lafayette,Louisiana', label: 'Lafayette,Louisiana' },
  { value: 'Midland,Texas', label: 'Midland,Texas' },
  { value: 'Surprise,Arizona', label: 'Surprise,Arizona' },
  { value: 'Denton,Texas', label: 'Denton,Texas' },
  { value: 'Victorville,California', label: 'Victorville,California' },
  { value: 'Evansville,Indiana', label: 'Evansville,Indiana' },
  { value: 'Santa Clara,California', label: 'Santa Clara,California' },
  { value: 'Abilene,Texas', label: 'Abilene,Texas' },
  { value: 'AthensClarke County,Georgia', label: 'AthensClarke County,Georgia' },
  { value: 'Vallejo,California', label: 'Vallejo,California' },
  { value: 'Allentown,Pennsylvania', label: 'Allentown,Pennsylvania' },
  { value: 'Norman,Oklahoma', label: 'Norman,Oklahoma' },
  { value: 'Beaumont,Texas', label: 'Beaumont,Texas' },
  { value: 'Independence,Missouri', label: 'Independence,Missouri' },
  { value: 'Murfreesboro,Tennessee', label: 'Murfreesboro,Tennessee' },
  { value: 'Ann Arbor,Michigan', label: 'Ann Arbor,Michigan' },
  { value: 'Springfield,Illinois', label: 'Springfield,Illinois' },
  { value: 'Berkeley,California', label: 'Berkeley,California' },
  { value: 'Peoria,Illinois', label: 'Peoria,Illinois' },
  { value: 'Provo,Utah', label: 'Provo,Utah' },
  { value: 'El Monte,California', label: 'El Monte,California' },
  { value: 'Columbia,Missouri', label: 'Columbia,Missouri' },
  { value: 'Lansing,Michigan', label: 'Lansing,Michigan' },
  { value: 'Fargo,North Dakota', label: 'Fargo,North Dakota' },
  { value: 'Downey,California', label: 'Downey,California' },
  { value: 'Costa Mesa,California', label: 'Costa Mesa,California' },
  { value: 'Wilmington,North Carolina', label: 'Wilmington,North Carolina' },
  { value: 'Arvada,Colorado', label: 'Arvada,Colorado' },
  { value: 'Inglewood,California', label: 'Inglewood,California' },
  { value: 'Miami Gardens,Florida', label: 'Miami Gardens,Florida' },
  { value: 'Carlsbad,California', label: 'Carlsbad,California' },
  { value: 'Westminster,Colorado', label: 'Westminster,Colorado' },
  { value: 'Rochester,Minnesota', label: 'Rochester,Minnesota' },
  { value: 'Odessa,Texas', label: 'Odessa,Texas' },
  { value: 'Manchester,New Hampshire', label: 'Manchester,New Hampshire' },
  { value: 'Elgin,Illinois', label: 'Elgin,Illinois' },
  { value: 'West Jordan,Utah', label: 'West Jordan,Utah' },
  { value: 'Round Rock,Texas', label: 'Round Rock,Texas' },
  { value: 'Clearwater,Florida', label: 'Clearwater,Florida' },
  { value: 'Waterbury,Connecticut', label: 'Waterbury,Connecticut' },
  { value: 'Gresham,Oregon', label: 'Gresham,Oregon' },
  { value: 'Fairfield,California', label: 'Fairfield,California' },
  { value: 'Billings,Montana', label: 'Billings,Montana' },
  { value: 'Lowell,Massachusetts', label: 'Lowell,Massachusetts' },
  {
    value: 'San Buenaventura (Ventura),California',
    label: 'San Buenaventura (Ventura),California',
  },
  { value: 'Pueblo,Colorado', label: 'Pueblo,Colorado' },
  { value: 'High Point,North Carolina', label: 'High Point,North Carolina' },
  { value: 'West Covina,California', label: 'West Covina,California' },
  { value: 'Richmond,California', label: 'Richmond,California' },
  { value: 'Murrieta,California', label: 'Murrieta,California' },
  { value: 'Cambridge,Massachusetts', label: 'Cambridge,Massachusetts' },
  { value: 'Antioch,California', label: 'Antioch,California' },
  { value: 'Temecula,California', label: 'Temecula,California' },
  { value: 'Norwalk,California', label: 'Norwalk,California' },
  { value: 'Centennial,Colorado', label: 'Centennial,Colorado' },
  { value: 'Everett,Washington', label: 'Everett,Washington' },
  { value: 'Palm Bay,Florida', label: 'Palm Bay,Florida' },
  { value: 'Wichita Falls,Texas', label: 'Wichita Falls,Texas' },
  { value: 'Green Bay,Wisconsin', label: 'Green Bay,Wisconsin' },
  { value: 'Daly City,California', label: 'Daly City,California' },
  { value: 'Burbank,California', label: 'Burbank,California' },
  { value: 'Richardson,Texas', label: 'Richardson,Texas' },
  { value: 'Pompano Beach,Florida', label: 'Pompano Beach,Florida' },
  { value: 'North Charleston,South Carolina', label: 'North Charleston,South Carolina' },
  { value: 'Broken Arrow,Oklahoma', label: 'Broken Arrow,Oklahoma' },
  { value: 'Boulder,Colorado', label: 'Boulder,Colorado' },
  { value: 'West Palm Beach,Florida', label: 'West Palm Beach,Florida' },
  { value: 'Santa Maria,California', label: 'Santa Maria,California' },
  { value: 'El Cajon,California', label: 'El Cajon,California' },
  { value: 'Davenport,Iowa', label: 'Davenport,Iowa' },
  { value: 'Rialto,California', label: 'Rialto,California' },
  { value: 'Las Cruces,New Mexico', label: 'Las Cruces,New Mexico' },
  { value: 'San Mateo,California', label: 'San Mateo,California' },
  { value: 'Lewisville,Texas', label: 'Lewisville,Texas' },
  { value: 'South Bend,Indiana', label: 'South Bend,Indiana' },
  { value: 'Lakeland,Florida', label: 'Lakeland,Florida' },
  { value: 'Erie,Pennsylvania', label: 'Erie,Pennsylvania' },
  { value: 'Tyler,Texas', label: 'Tyler,Texas' },
  { value: 'Pearland,Texas', label: 'Pearland,Texas' },
  { value: 'College Station,Texas', label: 'College Station,Texas' },
  { value: 'Kenosha,Wisconsin', label: 'Kenosha,Wisconsin' },
  { value: 'Sandy Springs,Georgia', label: 'Sandy Springs,Georgia' },
  { value: 'Clovis,California', label: 'Clovis,California' },
  { value: 'Flint,Michigan', label: 'Flint,Michigan' },
  { value: 'Roanoke,Virginia', label: 'Roanoke,Virginia' },
  { value: 'Albany,New York', label: 'Albany,New York' },
  { value: 'Jurupa Valley,Californi', label: 'Jurupa Valley,Californi' },
  { value: 'Compton,California', label: 'Compton,California' },
  { value: 'San Angelo,Texas', label: 'San Angelo,Texas' },
  { value: 'Hillsboro,Oregon', label: 'Hillsboro,Oregon' },
  { value: 'Lawton,Oklahoma', label: 'Lawton,Oklahoma' },
  { value: 'Renton,Washington', label: 'Renton,Washington' },
  { value: 'Vista,California', label: 'Vista,California' },
  { value: 'Davie,Florida', label: 'Davie,Florida' },
  { value: 'Greeley,Colorado', label: 'Greeley,Colorado' },
  { value: 'Mission Viejo,California', label: 'Mission Viejo,California' },
  { value: 'Portsmouth,Virginia', label: 'Portsmouth,Virginia' },
  { value: 'Dearborn,Michigan', label: 'Dearborn,Michigan' },
  { value: 'South Gate,California', label: 'South Gate,California' },
  { value: 'Tuscaloosa,Alabama', label: 'Tuscaloosa,Alabama' },
  { value: 'Livonia,Michigan', label: 'Livonia,Michigan' },
  { value: 'New Bedford,Massachusetts', label: 'New Bedford,Massachusetts' },
  { value: 'Vacaville,California', label: 'Vacaville,California' },
  { value: 'Brockton,Massachusetts', label: 'Brockton,Massachusetts' },
  { value: 'Roswell,Georgia', label: 'Roswell,Georgia' },
  { value: 'Beaverton,Oregon', label: 'Beaverton,Oregon' },
  { value: 'Quincy,Massachusetts', label: 'Quincy,Massachusetts' },
  { value: 'Sparks,Nevada', label: 'Sparks,Nevada' },
  { value: 'Yakima,Washington', label: 'Yakima,Washington' },
  { value: "Lee's Summit,Missouri", label: "Lee's Summit,Missouri" },
  { value: 'Federal Way,Washington', label: 'Federal Way,Washington' },
  { value: 'Carson,California', label: 'Carson,California' },
  { value: 'Santa Monica,California', label: 'Santa Monica,California' },
  { value: 'Hesperia,California', label: 'Hesperia,California' },
  { value: 'Allen,Texas', label: 'Allen,Texas' },
  { value: 'Rio Rancho,New Mexico', label: 'Rio Rancho,New Mexico' },
  { value: 'Yuma,Arizona', label: 'Yuma,Arizona' },
  { value: 'Westminster,California', label: 'Westminster,California' },
  { value: 'Orem,Utah', label: 'Orem,Utah' },
  { value: 'Lynn,Massachusetts', label: 'Lynn,Massachusetts' },
  { value: 'Redding,California', label: 'Redding,California' },
  { value: 'Spokane Valley,Washington', label: 'Spokane Valley,Washington' },
  { value: 'Miami Beach,Florida', label: 'Miami Beach,Florida' },
  { value: 'League City,Texas', label: 'League City,Texas' },
  { value: 'Lawrence,Kansas', label: 'Lawrence,Kansas' },
  { value: 'Santa Barbara,California', label: 'Santa Barbara,California' },
  { value: 'Plantation,Florida', label: 'Plantation,Florida' },
  { value: 'Sandy,Utah', label: 'Sandy,Utah' },
  { value: 'Sunrise,Florida', label: 'Sunrise,Florida' },
  { value: 'Macon,Georgia', label: 'Macon,Georgia' },
  { value: 'Longmont,Colorado', label: 'Longmont,Colorado' },
  { value: 'Boca Raton,Florida', label: 'Boca Raton,Florida' },
  { value: 'San Marcos,California', label: 'San Marcos,California' },
  { value: 'Greenville,North Carolina', label: 'Greenville,North Carolina' },
  { value: 'Waukegan,Illinois', label: 'Waukegan,Illinois' },
  { value: 'Fall River,Massachusetts', label: 'Fall River,Massachusetts' },
  { value: 'Chico,California', label: 'Chico,California' },
  { value: 'Newton,Massachusetts', label: 'Newton,Massachusetts' },
  { value: 'San Leandro,California', label: 'San Leandro,California' },
  { value: 'Reading,Pennsylvania', label: 'Reading,Pennsylvania' },
  { value: 'Norwalk,Connecticut', label: 'Norwalk,Connecticut' },
  { value: 'Fort Smith,Arkansas', label: 'Fort Smith,Arkansas' },
  { value: 'Newport Beach,California', label: 'Newport Beach,California' },
  { value: 'Asheville,North Carolina', label: 'Asheville,North Carolina' },
  { value: 'Nashua,New Hampshire', label: 'Nashua,New Hampshire' },
  { value: 'Edmond,Oklahoma', label: 'Edmond,Oklahoma' },
  { value: 'Whittier,California', label: 'Whittier,California' },
  { value: 'Nampa,Idaho', label: 'Nampa,Idaho' },
  { value: 'Bloomington,Minnesota', label: 'Bloomington,Minnesota' },
  { value: 'Deltona,Florida', label: 'Deltona,Florida' },
  { value: 'Hawthorne,California', label: 'Hawthorne,California' },
  { value: 'Duluth,Minnesota', label: 'Duluth,Minnesota' },
  { value: 'Carmel,Indiana', label: 'Carmel,Indiana' },
  { value: 'Suffolk,Virginia', label: 'Suffolk,Virginia' },
  { value: 'Clifton,New Jersey', label: 'Clifton,New Jersey' },
  { value: 'Citrus Heights,California', label: 'Citrus Heights,California' },
  { value: 'Livermore,California', label: 'Livermore,California' },
  { value: 'Tracy,California', label: 'Tracy,California' },
  { value: 'Alhambra,California', label: 'Alhambra,California' },
  { value: 'Kirkland,Washington', label: 'Kirkland,Washington' },
  { value: 'Trenton,New Jersey', label: 'Trenton,New Jersey' },
  { value: 'Ogden,Utah', label: 'Ogden,Utah' },
  { value: 'Hoover,Alabama', label: 'Hoover,Alabama' },
  { value: 'Cicero,Illinois', label: 'Cicero,Illinois' },
  { value: 'Fishers,Indiana', label: 'Fishers,Indiana' },
  { value: 'Sugar Land,Texas', label: 'Sugar Land,Texas' },
  { value: 'Danbury,Connecticut', label: 'Danbury,Connecticut' },
  { value: 'Meridian,Idaho', label: 'Meridian,Idaho' },
  { value: 'Indio,California', label: 'Indio,California' },
  { value: 'Concord,North Carolina', label: 'Concord,North Carolina' },
  { value: 'Menifee,California', label: 'Menifee,California' },
  { value: 'Champaign,Illinois', label: 'Champaign,Illinois' },
  { value: 'Buena Park,California', label: 'Buena Park,California' },
  { value: 'Troy,Michigan', label: 'Troy,Michigan' },
  { value: "O'Fallon,Missouri", label: "O'Fallon,Missouri" },
  { value: 'Johns Creek,Georgia', label: 'Johns Creek,Georgia' },
  { value: 'Bellingham,Washington', label: 'Bellingham,Washington' },
  { value: 'Westland,Michigan', label: 'Westland,Michigan' },
  { value: 'Bloomington,Indiana', label: 'Bloomington,Indiana' },
  { value: 'Sioux City,Iowa', label: 'Sioux City,Iowa' },
  { value: 'Warwick,Rhode Island', label: 'Warwick,Rhode Island' },
  { value: 'Hemet,California', label: 'Hemet,California' },
  { value: 'Longview,Texas', label: 'Longview,Texas' },
  { value: 'Farmington Hills,Michigan', label: 'Farmington Hills,Michigan' },
  { value: 'Bend,Oregon', label: 'Bend,Oregon' },
  { value: 'Lakewood,California', label: 'Lakewood,California' },
  { value: 'Merced,California', label: 'Merced,California' },
  { value: 'Mission,Texas', label: 'Mission,Texas' },
  { value: 'Chino,California', label: 'Chino,California' },
  { value: 'Redwood City,California', label: 'Redwood City,California' },
  { value: 'Edinburg,Texas', label: 'Edinburg,Texas' },
  { value: 'Cranston,Rhode Island', label: 'Cranston,Rhode Island' },
  { value: 'Parma,Ohio', label: 'Parma,Ohio' },
  { value: 'New Rochelle,New York', label: 'New Rochelle,New York' },
  { value: 'Lake Forest,California', label: 'Lake Forest,California' },
  { value: 'Napa,California', label: 'Napa,California' },
  { value: 'Hammond,Indiana', label: 'Hammond,Indiana' },
  { value: 'Fayetteville,Arkansas', label: 'Fayetteville,Arkansas' },
  { value: 'Bloomington,Illinois', label: 'Bloomington,Illinois' },
  { value: 'Avondale,Arizona', label: 'Avondale,Arizona' },
  { value: 'Somerville,Massachusetts', label: 'Somerville,Massachusetts' },
  { value: 'Palm Coast,Florida', label: 'Palm Coast,Florida' },
  { value: 'Bryan,Texas', label: 'Bryan,Texas' },
  { value: 'Gary,Indiana', label: 'Gary,Indiana' },
  { value: 'Largo,Florida', label: 'Largo,Florida' },
  { value: 'Brooklyn Park,Minnesota', label: 'Brooklyn Park,Minnesota' },
  { value: 'Tustin,California', label: 'Tustin,California' },
  { value: 'Racine,Wisconsin', label: 'Racine,Wisconsin' },
  { value: 'Deerfield Beach,Florida', label: 'Deerfield Beach,Florida' },
  { value: 'Lynchburg,Virginia', label: 'Lynchburg,Virginia' },
  { value: 'Mountain View,California', label: 'Mountain View,California' },
  { value: 'Medford,Oregon', label: 'Medford,Oregon' },
  { value: 'Lawrence,Massachusetts', label: 'Lawrence,Massachusetts' },
  { value: 'Bellflower,California', label: 'Bellflower,California' },
  { value: 'Melbourne,Florida', label: 'Melbourne,Florida' },
  { value: 'St Joseph,Missouri', label: 'St Joseph,Missouri' },
  { value: 'Camden,New Jersey', label: 'Camden,New Jersey' },
  { value: 'St George,Utah', label: 'St George,Utah' },
  { value: 'Kennewick,Washington', label: 'Kennewick,Washington' },
  { value: 'Baldwin Park,California', label: 'Baldwin Park,California' },
  { value: 'Chino Hills,California', label: 'Chino Hills,California' },
  { value: 'Alameda,California', label: 'Alameda,California' },
  { value: 'Albany,Georgia', label: 'Albany,Georgia' },
  { value: 'Arlington Heights,Illinois', label: 'Arlington Heights,Illinois' },
  { value: 'Scranton,Pennsylvania', label: 'Scranton,Pennsylvania' },
  { value: 'Evanston,Illinois', label: 'Evanston,Illinois' },
  { value: 'Kalamazoo,Michigan', label: 'Kalamazoo,Michigan' },
  { value: 'Baytown,Texas', label: 'Baytown,Texas' },
  { value: 'Upland,California', label: 'Upland,California' },
  { value: 'Springdale,Arkansas', label: 'Springdale,Arkansas' },
  { value: 'Bethlehem,Pennsylvania', label: 'Bethlehem,Pennsylvania' },
  { value: 'Schaumburg,Illinois', label: 'Schaumburg,Illinois' },
  { value: 'Mount Pleasant,South Carolina', label: 'Mount Pleasant,South Carolina' },
  { value: 'Auburn,Washington', label: 'Auburn,Washington' },
  { value: 'Decatur,Illinois', label: 'Decatur,Illinois' },
  { value: 'San Ramon,California', label: 'San Ramon,California' },
  { value: 'Pleasanton,California', label: 'Pleasanton,California' },
  { value: 'Wyoming,Michigan', label: 'Wyoming,Michigan' },
  { value: 'Lake Charles,Louisiana', label: 'Lake Charles,Louisiana' },
  { value: 'Plymouth,Minnesota', label: 'Plymouth,Minnesota' },
  { value: 'Bolingbrook,Illinois', label: 'Bolingbrook,Illinois' },
  { value: 'Pharr,Texas', label: 'Pharr,Texas' },
  { value: 'Appleton,Wisconsin', label: 'Appleton,Wisconsin' },
  { value: 'Gastonia,North Carolina', label: 'Gastonia,North Carolina' },
  { value: 'Folsom,California', label: 'Folsom,California' },
  { value: 'Southfield,Michigan', label: 'Southfield,Michigan' },
  { value: 'Rochester Hills,Michigan', label: 'Rochester Hills,Michigan' },
  { value: 'New Britain,Connecticut', label: 'New Britain,Connecticut' },
  { value: 'Goodyear,Arizona', label: 'Goodyear,Arizona' },
  { value: 'Canton,Ohio', label: 'Canton,Ohio' },
  { value: 'Warner Robins,Georgia', label: 'Warner Robins,Georgia' },
  { value: 'Union City,California', label: 'Union City,California' },
  { value: 'Perris,California', label: 'Perris,California' },
  { value: 'Manteca,California', label: 'Manteca,California' },
  { value: 'Iowa City,Iowa', label: 'Iowa City,Iowa' },
  { value: 'Jonesboro,Arkansas', label: 'Jonesboro,Arkansas' },
  { value: 'Wilmington,Delaware', label: 'Wilmington,Delaware' },
  { value: 'Lynwood,California', label: 'Lynwood,California' },
  { value: 'Loveland,Colorado', label: 'Loveland,Colorado' },
  { value: 'Pawtucket,Rhode Island', label: 'Pawtucket,Rhode Island' },
  { value: 'Boynton Beach,Florida', label: 'Boynton Beach,Florida' },
  { value: 'Waukesha,Wisconsin', label: 'Waukesha,Wisconsin' },
  { value: 'Gulfport,Mississippi', label: 'Gulfport,Mississippi' },
  { value: 'Apple Valley,California', label: 'Apple Valley,California' },
  { value: 'Passaic,New Jersey', label: 'Passaic,New Jersey' },
  { value: 'Rapid City,South Dakota', label: 'Rapid City,South Dakota' },
  { value: 'Layton,Utah', label: 'Layton,Utah' },
  { value: 'Lafayette,Indiana', label: 'Lafayette,Indiana' },
  { value: 'Turlock,California', label: 'Turlock,California' },
  { value: 'Muncie,Indiana', label: 'Muncie,Indiana' },
  { value: 'Temple,Texas', label: 'Temple,Texas' },
  { value: 'Missouri City,Texas', label: 'Missouri City,Texas' },
  { value: 'Redlands,California', label: 'Redlands,California' },
  { value: 'Santa Fe,New Mexico', label: 'Santa Fe,New Mexico' },
  { value: 'Lauderhill,Florida', label: 'Lauderhill,Florida' },
  { value: 'Milpitas,California', label: 'Milpitas,California' },
  { value: 'Palatine,Illinois', label: 'Palatine,Illinois' },
  { value: 'Missoula,Montana', label: 'Missoula,Montana' },
  { value: 'Rock Hill,South Carolina', label: 'Rock Hill,South Carolina' },
  { value: 'Jacksonville,North Carolina', label: 'Jacksonville,North Carolina' },
  { value: 'Franklin,Tennessee', label: 'Franklin,Tennessee' },
  { value: 'Flagstaff,Arizona', label: 'Flagstaff,Arizona' },
  { value: 'Flower Mound,Texas', label: 'Flower Mound,Texas' },
  { value: 'Weston,Florida', label: 'Weston,Florida' },
  { value: 'Waterloo,Iowa', label: 'Waterloo,Iowa' },
  { value: 'Union City,New Jersey', label: 'Union City,New Jersey' },
  { value: 'Mount Vernon,New York', label: 'Mount Vernon,New York' },
  { value: 'Fort Myers,Florida', label: 'Fort Myers,Florida' },
  { value: 'Dothan,Alabama', label: 'Dothan,Alabama' },
  { value: 'Rancho Cordova,California', label: 'Rancho Cordova,California' },
  { value: 'Redondo Beach,California', label: 'Redondo Beach,California' },
  { value: 'Jackson,Tennessee', label: 'Jackson,Tennessee' },
  { value: 'Pasco,Washington', label: 'Pasco,Washington' },
  { value: 'St Charles,Missouri', label: 'St Charles,Missouri' },
  { value: 'Eau Claire,Wisconsin', label: 'Eau Claire,Wisconsin' },
  { value: 'North Richland Hills,Texas', label: 'North Richland Hills,Texas' },
  { value: 'Bismarck,North Dakota', label: 'Bismarck,North Dakota' },
  { value: 'Yorba Linda,California', label: 'Yorba Linda,California' },
  { value: 'Kenner,Louisiana', label: 'Kenner,Louisiana' },
  { value: 'Walnut Creek,California', label: 'Walnut Creek,California' },
  { value: 'Frederick,Maryland', label: 'Frederick,Maryland' },
  { value: 'Oshkosh,Wisconsin', label: 'Oshkosh,Wisconsin' },
  { value: 'Pittsburg,California', label: 'Pittsburg,California' },
  { value: 'Palo Alto,California', label: 'Palo Alto,California' },
  { value: 'Bossier City,Louisiana', label: 'Bossier City,Louisiana' },
  { value: 'Portland,Maine', label: 'Portland,Maine' },
  { value: 'St Cloud,Minnesota', label: 'St Cloud,Minnesota' },
  { value: 'Davis,California', label: 'Davis,California' },
  { value: 'South San Francisco,California', label: 'South San Francisco,California' },
  { value: 'Camarillo,California', label: 'Camarillo,California' },
  { value: 'North Little Rock,Arkansas', label: 'North Little Rock,Arkansas' },
  { value: 'Schenectady,New York', label: 'Schenectady,New York' },
  { value: 'Gaithersburg,Maryland', label: 'Gaithersburg,Maryland' },
  { value: 'Harlingen,Texas', label: 'Harlingen,Texas' },
  { value: 'Woodbury,Minnesota', label: 'Woodbury,Minnesota' },
  { value: 'Eagan,Minnesota', label: 'Eagan,Minnesota' },
  { value: 'Yuba City,California', label: 'Yuba City,California' },
  { value: 'Maple Grove,Minnesota', label: 'Maple Grove,Minnesota' },
  { value: 'Youngstown,Ohio', label: 'Youngstown,Ohio' },
  { value: 'Skokie,Illinois', label: 'Skokie,Illinois' },
  { value: 'Kissimmee,Florida', label: 'Kissimmee,Florida' },
  { value: 'Johnson City,Tennessee', label: 'Johnson City,Tennessee' },
  { value: 'Victoria,Texas', label: 'Victoria,Texas' },
  { value: 'San Clemente,California', label: 'San Clemente,California' },
  { value: 'Bayonne,New Jersey', label: 'Bayonne,New Jersey' },
  { value: 'Laguna Niguel,California', label: 'Laguna Niguel,California' },
  { value: 'East Orange,New Jersey', label: 'East Orange,New Jersey' },
  { value: 'Shawnee,Kansas', label: 'Shawnee,Kansas' },
  { value: 'Homestead,Florida', label: 'Homestead,Florida' },
  { value: 'Rockville,Maryland', label: 'Rockville,Maryland' },
  { value: 'Delray Beach,Florida', label: 'Delray Beach,Florida' },
  { value: 'Janesville,Wisconsin', label: 'Janesville,Wisconsin' },
  { value: 'Conway,Arkansas', label: 'Conway,Arkansas' },
  { value: 'Pico Rivera,California', label: 'Pico Rivera,California' },
  { value: 'Lorain,Ohio', label: 'Lorain,Ohio' },
  { value: 'Montebello,California', label: 'Montebello,California' },
  { value: 'Lodi,California', label: 'Lodi,California' },
  { value: 'New Braunfels,Texas', label: 'New Braunfels,Texas' },
  { value: 'Marysville,Washington', label: 'Marysville,Washington' },
  { value: 'Tamarac,Florida', label: 'Tamarac,Florida' },
  { value: 'Madera,California', label: 'Madera,California' },
  { value: 'Conroe,Texas', label: 'Conroe,Texas' },
  { value: 'Santa Cruz,California', label: 'Santa Cruz,California' },
  { value: 'Eden Prairie,Minnesota', label: 'Eden Prairie,Minnesota' },
  { value: 'Cheyenne,Wyoming', label: 'Cheyenne,Wyoming' },
  { value: 'Daytona Beach,Florida', label: 'Daytona Beach,Florida' },
  { value: 'Alpharetta,Georgia', label: 'Alpharetta,Georgia' },
  { value: 'Hamilton,Ohio', label: 'Hamilton,Ohio' },
  { value: 'Waltham,Massachusetts', label: 'Waltham,Massachusetts' },
  { value: 'Coon Rapids,Minnesota', label: 'Coon Rapids,Minnesota' },
  { value: 'Haverhill,Massachusetts', label: 'Haverhill,Massachusetts' },
  { value: 'Council Bluffs,Iowa', label: 'Council Bluffs,Iowa' },
  { value: 'Taylor,Michigan', label: 'Taylor,Michigan' },
  { value: 'Utica,New York', label: 'Utica,New York' },
  { value: 'Ames,Iowa', label: 'Ames,Iowa' },
  { value: 'La Habra,California', label: 'La Habra,California' },
  { value: 'Encinitas,California', label: 'Encinitas,California' },
  { value: 'Bowling Green,Kentucky', label: 'Bowling Green,Kentucky' },
  { value: 'Burnsville,Minnesota', label: 'Burnsville,Minnesota' },
  { value: 'Greenville,South Carolina', label: 'Greenville,South Carolina' },
  { value: 'West Des Moines,Iowa', label: 'West Des Moines,Iowa' },
  { value: 'Cedar Park,Texas', label: 'Cedar Park,Texas' },
  { value: 'Tulare,California', label: 'Tulare,California' },
  { value: 'Monterey Park,California', label: 'Monterey Park,California' },
  { value: 'Vineland,New Jersey', label: 'Vineland,New Jersey' },
  { value: 'Terre Haute,Indiana', label: 'Terre Haute,Indiana' },
  { value: 'North Miami,Florida', label: 'North Miami,Florida' },
  { value: 'Mansfield,Texas', label: 'Mansfield,Texas' },
  { value: 'West Allis,Wisconsin', label: 'West Allis,Wisconsin' },
  { value: 'Bristol,Connecticut', label: 'Bristol,Connecticut' },
  { value: 'Taylorsville,Utah', label: 'Taylorsville,Utah' },
  { value: 'Malden,Massachusetts', label: 'Malden,Massachusetts' },
  { value: 'Meriden,Connecticut', label: 'Meriden,Connecticut' },
  { value: 'Blaine,Minnesota', label: 'Blaine,Minnesota' },
  { value: 'Wellington,Florida', label: 'Wellington,Florida' },
  { value: 'Cupertino,California', label: 'Cupertino,California' },
  { value: 'Springfield,Oregon', label: 'Springfield,Oregon' },
  { value: 'Rogers,Arkansas', label: 'Rogers,Arkansas' },
  { value: 'St Clair Shores,Michigan', label: 'St Clair Shores,Michigan' },
  { value: 'Gardena,California', label: 'Gardena,California' },
  { value: 'Pontiac,Michigan', label: 'Pontiac,Michigan' },
  { value: 'National City,California', label: 'National City,California' },
  { value: 'Grand Junction,Colorado', label: 'Grand Junction,Colorado' },
  { value: 'Rocklin,California', label: 'Rocklin,California' },
  { value: 'Chapel Hill,North Carolina', label: 'Chapel Hill,North Carolina' },
  { value: 'Casper,Wyoming', label: 'Casper,Wyoming' },
  { value: 'Broomfield,Colorado', label: 'Broomfield,Colorado' },
  { value: 'Petaluma,California', label: 'Petaluma,California' },
  { value: 'South Jordan,Utah', label: 'South Jordan,Utah' },
  { value: 'Springfield,Ohio', label: 'Springfield,Ohio' },
  { value: 'Great Falls,Montana', label: 'Great Falls,Montana' },
  { value: 'Lancaster,Pennsylvania', label: 'Lancaster,Pennsylvania' },
  { value: 'North Port,Florida', label: 'North Port,Florida' },
  { value: 'Lakewood,Washington', label: 'Lakewood,Washington' },
  { value: 'Marietta,Georgia', label: 'Marietta,Georgia' },
  { value: 'San Rafael,California', label: 'San Rafael,California' },
  { value: 'Royal Oak,Michigan', label: 'Royal Oak,Michigan' },
  { value: 'Des Plaines,Illinois', label: 'Des Plaines,Illinois' },
  { value: 'Huntington Park,California', label: 'Huntington Park,California' },
  { value: 'La Mesa,California', label: 'La Mesa,California' },
  { value: 'Orland Park,Illinois', label: 'Orland Park,Illinois' },
  { value: 'Auburn,Alabama', label: 'Auburn,Alabama' },
  { value: 'Lakeville,Minnesota', label: 'Lakeville,Minnesota' },
  { value: 'Owensboro,Kentucky', label: 'Owensboro,Kentucky' },
  { value: 'Moore,Oklahoma', label: 'Moore,Oklahoma' },
  { value: 'Jupiter,Florida', label: 'Jupiter,Florida' },
  { value: 'Idaho Falls,Idaho', label: 'Idaho Falls,Idaho' },
  { value: 'Dubuque,Iowa', label: 'Dubuque,Iowa' },
  { value: 'Bartlett,Tennessee', label: 'Bartlett,Tennessee' },
  { value: 'Rowlett,Texas', label: 'Rowlett,Texas' },
  { value: 'Novi,Michigan', label: 'Novi,Michigan' },
  { value: 'White Plains,New York', label: 'White Plains,New York' },
  { value: 'Arcadia,California', label: 'Arcadia,California' },
  { value: 'Redmond,Washington', label: 'Redmond,Washington' },
  { value: 'Lake Elsinore,California', label: 'Lake Elsinore,California' },
  { value: 'Ocala,Florida', label: 'Ocala,Florida' },
  { value: 'Tinley Park,Illinois', label: 'Tinley Park,Illinois' },
  { value: 'Port Orange,Florida', label: 'Port Orange,Florida' },
  { value: 'Medford,Massachusetts', label: 'Medford,Massachusetts' },
  { value: 'Oak Lawn,Illinois', label: 'Oak Lawn,Illinois' },
  { value: 'Rocky Mount,North Carolina', label: 'Rocky Mount,North Carolina' },
  { value: 'Kokomo,Indiana', label: 'Kokomo,Indiana' },
  { value: 'Coconut Creek,Florida', label: 'Coconut Creek,Florida' },
  { value: 'Bowie,Maryland', label: 'Bowie,Maryland' },
  { value: 'Berwyn,Illinois', label: 'Berwyn,Illinois' },
  { value: 'Midwest City,Oklahoma', label: 'Midwest City,Oklahoma' },
  { value: 'Fountain Valley,California', label: 'Fountain Valley,California' },
  { value: 'Buckeye,Arizona', label: 'Buckeye,Arizona' },
  { value: 'Dearborn Heights,Michigan', label: 'Dearborn Heights,Michigan' },
  { value: 'Woodland,California', label: 'Woodland,California' },
  { value: 'Noblesville,Indiana', label: 'Noblesville,Indiana' },
  { value: 'Valdosta,Georgia', label: 'Valdosta,Georgia' },
  { value: 'Diamond Bar,California', label: 'Diamond Bar,California' },
  { value: 'Manhattan,Kansas', label: 'Manhattan,Kansas' },
  { value: 'Santee,California', label: 'Santee,California' },
  { value: 'Taunton,Massachusetts', label: 'Taunton,Massachusetts' },
  { value: 'Sanford,Florida', label: 'Sanford,Florida' },
  { value: 'Kettering,Ohio', label: 'Kettering,Ohio' },
  { value: 'New Brunswick,New Jersey', label: 'New Brunswick,New Jersey' },
  { value: 'Decatur,Alabama', label: 'Decatur,Alabama' },
  { value: 'Chicopee,Massachusetts', label: 'Chicopee,Massachusetts' },
  { value: 'Anderson,Indiana', label: 'Anderson,Indiana' },
  { value: 'Margate,Florida', label: 'Margate,Florida' },
  { value: 'Weymouth Town,Massachusett', label: 'Weymouth Town,Massachusett' },
  { value: 'Hempstead,New York', label: 'Hempstead,New York' },
  { value: 'Corvallis,Oregon', label: 'Corvallis,Oregon' },
  { value: 'Eastvale,Californi', label: 'Eastvale,Californi' },
  { value: 'Porterville,California', label: 'Porterville,California' },
  { value: 'West Haven,Connecticut', label: 'West Haven,Connecticut' },
  { value: 'Brentwood,California', label: 'Brentwood,California' },
  { value: 'Paramount,California', label: 'Paramount,California' },
  { value: 'Grand Forks,North Dakota', label: 'Grand Forks,North Dakota' },
  { value: 'Georgetown,Texas', label: 'Georgetown,Texas' },
  { value: 'St Peters,Missouri', label: 'St Peters,Missouri' },
  { value: 'Shoreline,Washington', label: 'Shoreline,Washington' },
  { value: 'Mount Prospect,Illinois', label: 'Mount Prospect,Illinois' },
  { value: 'Hanford,California', label: 'Hanford,California' },
  { value: 'Normal,Illinois', label: 'Normal,Illinois' },
  { value: 'Rosemead,California', label: 'Rosemead,California' },
  { value: 'Lehi,Utah', label: 'Lehi,Utah' },
  { value: 'Pocatello,Idaho', label: 'Pocatello,Idaho' },
  { value: 'Highland,California', label: 'Highland,California' },
  { value: 'Novato,California', label: 'Novato,California' },
  { value: 'Port Arthur,Texas', label: 'Port Arthur,Texas' },
  { value: 'Carson City,Nevada', label: 'Carson City,Nevada' },
  { value: 'San Marcos,Texas', label: 'San Marcos,Texas' },
  { value: 'Hendersonville,Tennessee', label: 'Hendersonville,Tennessee' },
  { value: 'Elyria,Ohio', label: 'Elyria,Ohio' },
  { value: 'Revere,Massachusetts', label: 'Revere,Massachusetts' },
  { value: 'Pflugerville,Texas', label: 'Pflugerville,Texas' },
  { value: 'Greenwood,Indiana', label: 'Greenwood,Indiana' },
  { value: 'Bellevue,Nebraska', label: 'Bellevue,Nebraska' },
  { value: 'Wheaton,Illinois', label: 'Wheaton,Illinois' },
  { value: 'Smyrna,Georgia', label: 'Smyrna,Georgia' },
  { value: 'Sarasota,Florida', label: 'Sarasota,Florida' },
  { value: 'Blue Springs,Missouri', label: 'Blue Springs,Missouri' },
  { value: 'Colton,California', label: 'Colton,California' },
  { value: 'Euless,Texas', label: 'Euless,Texas' },
  { value: 'Castle Rock,Colorado', label: 'Castle Rock,Colorado' },
  { value: 'Cathedral City,California', label: 'Cathedral City,California' },
  { value: 'Kingsport,Tennessee', label: 'Kingsport,Tennessee' },
  { value: 'Lake Havasu City,Arizona', label: 'Lake Havasu City,Arizona' },
  { value: 'Pensacola,Florida', label: 'Pensacola,Florida' },
  { value: 'Hoboken,New Jersey', label: 'Hoboken,New Jersey' },
  { value: 'Yucaipa,California', label: 'Yucaipa,California' },
  { value: 'Watsonville,California', label: 'Watsonville,California' },
  { value: 'Richland,Washington', label: 'Richland,Washington' },
  { value: 'Delano,California', label: 'Delano,California' },
  { value: 'Hoffman Estates,Illinois', label: 'Hoffman Estates,Illinois' },
  { value: 'Florissant,Missouri', label: 'Florissant,Missouri' },
  { value: 'Placentia,California', label: 'Placentia,California' },
  { value: 'West New York,New Jersey', label: 'West New York,New Jersey' },
  { value: 'Dublin,California', label: 'Dublin,California' },
  { value: 'Oak Park,Illinois', label: 'Oak Park,Illinois' },
  { value: 'Peabody,Massachusetts', label: 'Peabody,Massachusetts' },
  { value: 'Perth Amboy,New Jersey', label: 'Perth Amboy,New Jersey' },
  { value: 'Battle Creek,Michigan', label: 'Battle Creek,Michigan' },
  { value: 'Bradenton,Florida', label: 'Bradenton,Florida' },
  { value: 'Gilroy,California', label: 'Gilroy,California' },
  { value: 'Milford,Connecticut', label: 'Milford,Connecticut' },
  { value: 'Albany,Oregon', label: 'Albany,Oregon' },
  { value: 'Ankeny,Iowa', label: 'Ankeny,Iowa' },
  { value: 'La Crosse,Wisconsin', label: 'La Crosse,Wisconsin' },
  { value: 'Burlington,North Carolina', label: 'Burlington,North Carolina' },
  { value: 'DeSoto,Texas', label: 'DeSoto,Texas' },
  { value: 'Harrisonburg,Virginia', label: 'Harrisonburg,Virginia' },
  { value: 'Minnetonka,Minnesota', label: 'Minnetonka,Minnesota' },
  { value: 'Elkhart,Indiana', label: 'Elkhart,Indiana' },
  { value: 'Lakewood,Ohio', label: 'Lakewood,Ohio' },
  { value: 'Glendora,California', label: 'Glendora,California' },
  { value: 'Southaven,Mississippi', label: 'Southaven,Mississippi' },
  { value: 'Charleston,West Virginia', label: 'Charleston,West Virginia' },
  { value: 'Joplin,Missouri', label: 'Joplin,Missouri' },
  { value: 'Enid,Oklahoma', label: 'Enid,Oklahoma' },
  { value: 'Palm Beach Gardens,Florida', label: 'Palm Beach Gardens,Florida' },
  { value: 'Brookhaven,Georgi', label: 'Brookhaven,Georgi' },
  { value: 'Plainfield,New Jersey', label: 'Plainfield,New Jersey' },
  { value: 'Grand Island,Nebraska', label: 'Grand Island,Nebraska' },
  { value: 'Palm Desert,California', label: 'Palm Desert,California' },
  { value: 'Huntersville,North Carolina', label: 'Huntersville,North Carolina' },
  { value: 'Tigard,Oregon', label: 'Tigard,Oregon' },
  { value: 'Lenexa,Kansas', label: 'Lenexa,Kansas' },
  { value: 'Saginaw,Michigan', label: 'Saginaw,Michigan' },
  { value: 'Kentwood,Michigan', label: 'Kentwood,Michigan' },
  { value: 'Doral,Florida', label: 'Doral,Florida' },
  { value: 'Apple Valley,Minnesota', label: 'Apple Valley,Minnesota' },
  { value: 'Grapevine,Texas', label: 'Grapevine,Texas' },
  { value: 'Aliso Viejo,California', label: 'Aliso Viejo,California' },
  { value: 'Sammamish,Washington', label: 'Sammamish,Washington' },
  { value: 'Casa Grande,Arizona', label: 'Casa Grande,Arizona' },
  { value: 'Pinellas Park,Florida', label: 'Pinellas Park,Florida' },
  { value: 'Troy,New York', label: 'Troy,New York' },
  { value: 'West Sacramento,California', label: 'West Sacramento,California' },
  { value: 'Burien,Washington', label: 'Burien,Washington' },
  { value: 'Commerce City,Colorado', label: 'Commerce City,Colorado' },
  { value: 'Monroe,Louisiana', label: 'Monroe,Louisiana' },
  { value: 'Cerritos,California', label: 'Cerritos,California' },
  { value: 'Downers Grove,Illinois', label: 'Downers Grove,Illinois' },
  { value: 'Coral Gables,Florida', label: 'Coral Gables,Florida' },
  { value: 'Wilson,North Carolina', label: 'Wilson,North Carolina' },
  { value: 'Niagara Falls,New York', label: 'Niagara Falls,New York' },
  { value: 'Poway,California', label: 'Poway,California' },
  { value: 'Edina,Minnesota', label: 'Edina,Minnesota' },
  { value: 'Cuyahoga Falls,Ohio', label: 'Cuyahoga Falls,Ohio' },
  { value: 'Rancho Santa Margarita,California', label: 'Rancho Santa Margarita,California' },
  { value: 'Harrisburg,Pennsylvania', label: 'Harrisburg,Pennsylvania' },
  { value: 'Huntington,West Virginia', label: 'Huntington,West Virginia' },
  { value: 'La Mirada,California', label: 'La Mirada,California' },
  { value: 'Cypress,California', label: 'Cypress,California' },
  { value: 'Caldwell,Idaho', label: 'Caldwell,Idaho' },
  { value: 'Logan,Utah', label: 'Logan,Utah' },
  { value: 'Galveston,Texas', label: 'Galveston,Texas' },
  { value: 'Sheboygan,Wisconsin', label: 'Sheboygan,Wisconsin' },
  { value: 'Middletown,Ohio', label: 'Middletown,Ohio' },
  { value: 'Murray,Utah', label: 'Murray,Utah' },
  { value: 'Roswell,New Mexico', label: 'Roswell,New Mexico' },
  { value: 'Parker,Colorado', label: 'Parker,Colorado' },
  { value: 'Bedford,Texas', label: 'Bedford,Texas' },
  { value: 'East Lansing,Michigan', label: 'East Lansing,Michigan' },
  { value: 'Methuen,Massachusetts', label: 'Methuen,Massachusetts' },
  { value: 'Covina,California', label: 'Covina,California' },
  { value: 'Alexandria,Louisiana', label: 'Alexandria,Louisiana' },
  { value: 'Olympia,Washington', label: 'Olympia,Washington' },
  { value: 'Euclid,Ohio', label: 'Euclid,Ohio' },
  { value: 'Mishawaka,Indiana', label: 'Mishawaka,Indiana' },
  { value: 'Salina,Kansas', label: 'Salina,Kansas' },
  { value: 'Azusa,California', label: 'Azusa,California' },
  { value: 'Newark,Ohio', label: 'Newark,Ohio' },
  { value: 'Chesterfield,Missouri', label: 'Chesterfield,Missouri' },
  { value: 'Leesburg,Virginia', label: 'Leesburg,Virginia' },
  { value: 'Dunwoody,Georgi', label: 'Dunwoody,Georgi' },
  { value: 'Hattiesburg,Mississippi', label: 'Hattiesburg,Mississippi' },
  { value: 'Roseville,Michigan', label: 'Roseville,Michigan' },
  { value: 'Bonita Springs,Florida', label: 'Bonita Springs,Florida' },
  { value: 'Portage,Michigan', label: 'Portage,Michigan' },
  { value: 'St Louis Park,Minnesota', label: 'St Louis Park,Minnesota' },
  { value: 'Collierville,Tennessee', label: 'Collierville,Tennessee' },
  { value: 'Middletown,Connecticut', label: 'Middletown,Connecticut' },
  { value: 'Stillwater,Oklahoma', label: 'Stillwater,Oklahoma' },
  { value: 'East Providence,Rhode Island', label: 'East Providence,Rhode Island' },
  { value: 'Lawrence,Indiana', label: 'Lawrence,Indiana' },
  { value: 'Wauwatosa,Wisconsin', label: 'Wauwatosa,Wisconsin' },
  { value: 'Mentor,Ohio', label: 'Mentor,Ohio' },
  { value: 'Ceres,California', label: 'Ceres,California' },
  { value: 'Cedar Hill,Texas', label: 'Cedar Hill,Texas' },
  { value: 'Mansfield,Ohio', label: 'Mansfield,Ohio' },
  { value: 'Binghamton,New York', label: 'Binghamton,New York' },
  { value: "Coeur d'Alene,Idaho", label: "Coeur d'Alene,Idaho" },
  { value: 'San Luis Obispo,California', label: 'San Luis Obispo,California' },
  { value: 'Minot,North Dakota', label: 'Minot,North Dakota' },
  { value: 'Palm Springs,California', label: 'Palm Springs,California' },
  { value: 'Pine Bluff,Arkansas', label: 'Pine Bluff,Arkansas' },
  { value: 'Texas City,Texas', label: 'Texas City,Texas' },
  { value: 'Summerville,South Carolina', label: 'Summerville,South Carolina' },
  { value: 'Twin Falls,Idaho', label: 'Twin Falls,Idaho' },
  { value: 'Jeffersonville,Indiana', label: 'Jeffersonville,Indiana' },
  { value: 'San Jacinto,California', label: 'San Jacinto,California' },
  { value: 'Madison,Alabama', label: 'Madison,Alabama' },
  { value: 'Altoona,Pennsylvania', label: 'Altoona,Pennsylvania' },
  { value: 'Columbus,Indiana', label: 'Columbus,Indiana' },
  { value: 'Beavercreek,Ohio', label: 'Beavercreek,Ohio' },
  { value: 'Apopka,Florida', label: 'Apopka,Florida' },
  { value: 'Elmhurst,Illinois', label: 'Elmhurst,Illinois' },
  { value: 'Maricopa,Arizona', label: 'Maricopa,Arizona' },
  { value: 'Farmington,New Mexico', label: 'Farmington,New Mexico' },
  { value: 'Glenview,Illinois', label: 'Glenview,Illinois' },
  { value: 'Cleveland Heights,Ohio', label: 'Cleveland Heights,Ohio' },
  { value: 'Draper,Utah', label: 'Draper,Utah' },
  { value: 'Lincoln,California', label: 'Lincoln,California' },
  { value: 'Sierra Vista,Arizona', label: 'Sierra Vista,Arizona' },
  { value: 'Lacey,Washington', label: 'Lacey,Washington' },
  { value: 'Biloxi,Mississippi', label: 'Biloxi,Mississippi' },
  { value: 'Strongsville,Ohio', label: 'Strongsville,Ohio' },
  { value: 'Barnstable Town,Massachusetts', label: 'Barnstable Town,Massachusetts' },
  { value: 'Wylie,Texas', label: 'Wylie,Texas' },
  { value: 'Sayreville,New Jersey', label: 'Sayreville,New Jersey' },
  { value: 'Kannapolis,North Carolina', label: 'Kannapolis,North Carolina' },
  { value: 'Charlottesville,Virginia', label: 'Charlottesville,Virginia' },
  { value: 'Littleton,Colorado', label: 'Littleton,Colorado' },
  { value: 'Titusville,Florida', label: 'Titusville,Florida' },
  { value: 'Hackensack,New Jersey', label: 'Hackensack,New Jersey' },
  { value: 'Newark,California', label: 'Newark,California' },
  { value: 'Pittsfield,Massachusetts', label: 'Pittsfield,Massachusetts' },
  { value: 'York,Pennsylvania', label: 'York,Pennsylvania' },
  { value: 'Lombard,Illinois', label: 'Lombard,Illinois' },
  { value: 'Attleboro,Massachusetts', label: 'Attleboro,Massachusetts' },
  { value: 'DeKalb,Illinois', label: 'DeKalb,Illinois' },
  { value: 'Blacksburg,Virginia', label: 'Blacksburg,Virginia' },
  { value: 'Dublin,Ohio', label: 'Dublin,Ohio' },
  { value: 'Haltom City,Texas', label: 'Haltom City,Texas' },
  { value: 'Lompoc,California', label: 'Lompoc,California' },
  { value: 'El Centro,California', label: 'El Centro,California' },
  { value: 'Danville,California', label: 'Danville,California' },
  { value: 'Jefferson City,Missouri', label: 'Jefferson City,Missouri' },
  { value: 'Cutler Bay,Florida', label: 'Cutler Bay,Florida' },
  { value: 'Oakland Park,Florida', label: 'Oakland Park,Florida' },
  { value: 'North Miami Beach,Florida', label: 'North Miami Beach,Florida' },
  { value: 'Freeport,New York', label: 'Freeport,New York' },
  { value: 'Moline,Illinois', label: 'Moline,Illinois' },
  { value: 'Coachella,California', label: 'Coachella,California' },
  { value: 'Fort Pierce,Florida', label: 'Fort Pierce,Florida' },
  { value: 'Smyrna,Tennessee', label: 'Smyrna,Tennessee' },
  { value: 'Bountiful,Utah', label: 'Bountiful,Utah' },
  { value: 'Fond du Lac,Wisconsin', label: 'Fond du Lac,Wisconsin' },
  { value: 'Everett,Massachusetts', label: 'Everett,Massachusetts' },
  { value: 'Danville,Virginia', label: 'Danville,Virginia' },
  { value: 'Keller,Texas', label: 'Keller,Texas' },
  { value: 'Belleville,Illinois', label: 'Belleville,Illinois' },
  { value: 'Bell Gardens,California', label: 'Bell Gardens,California' },
  { value: 'Cleveland,Tennessee', label: 'Cleveland,Tennessee' },
  { value: 'North Lauderdale,Florida', label: 'North Lauderdale,Florida' },
  { value: 'Fairfield,Ohio', label: 'Fairfield,Ohio' },
  { value: 'Salem,Massachusetts', label: 'Salem,Massachusetts' },
  { value: 'Rancho Palos Verdes,California', label: 'Rancho Palos Verdes,California' },
  { value: 'San Bruno,California', label: 'San Bruno,California' },
  { value: 'Concord,New Hampshire', label: 'Concord,New Hampshire' },
  { value: 'Burlington,Vermont', label: 'Burlington,Vermont' },
  { value: 'Apex,North Carolina', label: 'Apex,North Carolina' },
  { value: 'Midland,Michigan', label: 'Midland,Michigan' },
  { value: 'Altamonte Springs,Florida', label: 'Altamonte Springs,Florida' },
  { value: 'Hutchinson,Kansas', label: 'Hutchinson,Kansas' },
  { value: 'Buffalo Grove,Illinois', label: 'Buffalo Grove,Illinois' },
  { value: 'Urbandale,Iowa', label: 'Urbandale,Iowa' },
  { value: 'State College,Pennsylvania', label: 'State College,Pennsylvania' },
  { value: 'Urbana,Illinois', label: 'Urbana,Illinois' },
  { value: 'Plainfield,Illinois', label: 'Plainfield,Illinois' },
  { value: 'Manassas,Virginia', label: 'Manassas,Virginia' },
  { value: 'Bartlett,Illinois', label: 'Bartlett,Illinois' },
  { value: 'Kearny,New Jersey', label: 'Kearny,New Jersey' },
  { value: 'Oro Valley,Arizona', label: 'Oro Valley,Arizona' },
  { value: 'Findlay,Ohio', label: 'Findlay,Ohio' },
  { value: 'Rohnert Park,California', label: 'Rohnert Park,California' },
  { value: 'Westfield,Massachusetts', label: 'Westfield,Massachusetts' },
  { value: 'Linden,New Jersey', label: 'Linden,New Jersey' },
  { value: 'Sumter,South Carolina', label: 'Sumter,South Carolina' },
  { value: 'WilkesBarre,Pennsylvania', label: 'WilkesBarre,Pennsylvania' },
  { value: 'Woonsocket,Rhode Island', label: 'Woonsocket,Rhode Island' },
  { value: 'Leominster,Massachusetts', label: 'Leominster,Massachusetts' },
  { value: 'Shelton,Connecticut', label: 'Shelton,Connecticut' },
  { value: 'Brea,California', label: 'Brea,California' },
  { value: 'Covington,Kentucky', label: 'Covington,Kentucky' },
  { value: 'Rockwall,Texas', label: 'Rockwall,Texas' },
  { value: 'Meridian,Mississippi', label: 'Meridian,Mississippi' },
  { value: 'Riverton,Utah', label: 'Riverton,Utah' },
  { value: 'St Cloud,Florida', label: 'St Cloud,Florida' },
  { value: 'Quincy,Illinois', label: 'Quincy,Illinois' },
  { value: 'Morgan Hill,California', label: 'Morgan Hill,California' },
  { value: 'Warren,Ohio', label: 'Warren,Ohio' },
  { value: 'Edmonds,Washington', label: 'Edmonds,Washington' },
  { value: 'Burleson,Texas', label: 'Burleson,Texas' },
  { value: 'Beverly,Massachusetts', label: 'Beverly,Massachusetts' },
  { value: 'Mankato,Minnesota', label: 'Mankato,Minnesota' },
  { value: 'Hagerstown,Maryland', label: 'Hagerstown,Maryland' },
  { value: 'Prescott,Arizona', label: 'Prescott,Arizona' },
  { value: 'Campbell,California', label: 'Campbell,California' },
  { value: 'Cedar Falls,Iowa', label: 'Cedar Falls,Iowa' },
  { value: 'Beaumont,California', label: 'Beaumont,California' },
  { value: 'La Puente,California', label: 'La Puente,California' },
  { value: 'Crystal Lake,Illinois', label: 'Crystal Lake,Illinois' },
  { value: 'Fitchburg,Massachusetts', label: 'Fitchburg,Massachusetts' },
  { value: 'Carol Stream,Illinois', label: 'Carol Stream,Illinois' },
  { value: 'Hickory,North Carolina', label: 'Hickory,North Carolina' },
  { value: 'Streamwood,Illinois', label: 'Streamwood,Illinois' },
  { value: 'Norwich,Connecticut', label: 'Norwich,Connecticut' },
  { value: 'Coppell,Texas', label: 'Coppell,Texas' },
  { value: 'San Gabriel,California', label: 'San Gabriel,California' },
  { value: 'Holyoke,Massachusetts', label: 'Holyoke,Massachusetts' },
  { value: 'Bentonville,Arkansas', label: 'Bentonville,Arkansas' },
  { value: 'Florence,Alabama', label: 'Florence,Alabama' },
  { value: 'Peachtree Corners,Georgia', label: 'Peachtree Corners,Georgia' },
  { value: 'Brentwood,Tennessee', label: 'Brentwood,Tennessee' },
  { value: 'Bozeman,Montana', label: 'Bozeman,Montana' },
  { value: 'New Berlin,Wisconsin', label: 'New Berlin,Wisconsin' },
  { value: 'Goose Creek,South Carolina', label: 'Goose Creek,South Carolina' },
  { value: 'Huntsville,Texas', label: 'Huntsville,Texas' },
  { value: 'Prescott Valley,Arizona', label: 'Prescott Valley,Arizona' },
  { value: 'Maplewood,Minnesota', label: 'Maplewood,Minnesota' },
  { value: 'Romeoville,Illinois', label: 'Romeoville,Illinois' },
  { value: 'Duncanville,Texas', label: 'Duncanville,Texas' },
  { value: 'Atlantic City,New Jersey', label: 'Atlantic City,New Jersey' },
  { value: 'Clovis,New Mexico', label: 'Clovis,New Mexico' },
  { value: 'The Colony,Texas', label: 'The Colony,Texas' },
  { value: 'Culver City,California', label: 'Culver City,California' },
  { value: 'Marlborough,Massachusetts', label: 'Marlborough,Massachusetts' },
  { value: 'Hilton Head Island,South Carolina', label: 'Hilton Head Island,South Carolina' },
  { value: 'Moorhead,Minnesota', label: 'Moorhead,Minnesota' },
  { value: 'Calexico,California', label: 'Calexico,California' },
  { value: 'Bullhead City,Arizona', label: 'Bullhead City,Arizona' },
  { value: 'Germantown,Tennessee', label: 'Germantown,Tennessee' },
  { value: 'La Quinta,California', label: 'La Quinta,California' },
  { value: 'Lancaster,Ohio', label: 'Lancaster,Ohio' },
  { value: 'Wausau,Wisconsin', label: 'Wausau,Wisconsin' },
  { value: 'Sherman,Texas', label: 'Sherman,Texas' },
  { value: 'Ocoee,Florida', label: 'Ocoee,Florida' },
  { value: 'Shakopee,Minnesota', label: 'Shakopee,Minnesota' },
  { value: 'Woburn,Massachusetts', label: 'Woburn,Massachusetts' },
  { value: 'Bremerton,Washington', label: 'Bremerton,Washington' },
  { value: 'Rock Island,Illinois', label: 'Rock Island,Illinois' },
  { value: 'Muskogee,Oklahoma', label: 'Muskogee,Oklahoma' },
  { value: 'Cape Girardeau,Missouri', label: 'Cape Girardeau,Missouri' },
  { value: 'Annapolis,Maryland', label: 'Annapolis,Maryland' },
  { value: 'Greenacres,Florida', label: 'Greenacres,Florida' },
  { value: 'Ormond Beach,Florida', label: 'Ormond Beach,Florida' },
  { value: 'Hallandale Beach,Florida', label: 'Hallandale Beach,Florida' },
  { value: 'Stanton,California', label: 'Stanton,California' },
  { value: 'Puyallup,Washington', label: 'Puyallup,Washington' },
  { value: 'Pacifica,California', label: 'Pacifica,California' },
  { value: 'Hanover Park,Illinois', label: 'Hanover Park,Illinois' },
  { value: 'Hurst,Texas', label: 'Hurst,Texas' },
  { value: 'Lima,Ohio', label: 'Lima,Ohio' },
  { value: 'Marana,Arizona', label: 'Marana,Arizona' },
  { value: 'Carpentersville,Illinois', label: 'Carpentersville,Illinois' },
  { value: 'Oakley,California', label: 'Oakley,California' },
  { value: 'Huber Heights,Ohio', label: 'Huber Heights,Ohio' },
  { value: 'Lancaster,Texas', label: 'Lancaster,Texas' },
  { value: 'Montclair,California', label: 'Montclair,California' },
  { value: 'Wheeling,Illinois', label: 'Wheeling,Illinois' },
  { value: 'Brookfield,Wisconsin', label: 'Brookfield,Wisconsin' },
  { value: 'Park Ridge,Illinois', label: 'Park Ridge,Illinois' },
  { value: 'Florence,South Carolina', label: 'Florence,South Carolina' },
  { value: 'Roy,Utah', label: 'Roy,Utah' },
  { value: 'Winter Garden,Florida', label: 'Winter Garden,Florida' },
  { value: 'Chelsea,Massachusetts', label: 'Chelsea,Massachusetts' },
  { value: 'Valley Stream,New York', label: 'Valley Stream,New York' },
  { value: 'Spartanburg,South Carolina', label: 'Spartanburg,South Carolina' },
  { value: 'Lake Oswego,Oregon', label: 'Lake Oswego,Oregon' },
  { value: 'Friendswood,Texas', label: 'Friendswood,Texas' },
  { value: 'Westerville,Ohio', label: 'Westerville,Ohio' },
  { value: 'Northglenn,Colorado', label: 'Northglenn,Colorado' },
  { value: 'Phenix City,Alabama', label: 'Phenix City,Alabama' },
  { value: 'Grove City,Ohio', label: 'Grove City,Ohio' },
  { value: 'Texarkana,Texas', label: 'Texarkana,Texas' },
  { value: 'Addison,Illinois', label: 'Addison,Illinois' },
  { value: 'Dover,Delaware', label: 'Dover,Delaware' },
  { value: 'Lincoln Park,Michigan', label: 'Lincoln Park,Michigan' },
  { value: 'Calumet City,Illinois', label: 'Calumet City,Illinois' },
  { value: 'Muskegon,Michigan', label: 'Muskegon,Michigan' },
  { value: 'Aventura,Florida', label: 'Aventura,Florida' },
  { value: 'Martinez,California', label: 'Martinez,California' },
  { value: 'Greenfield,Wisconsin', label: 'Greenfield,Wisconsin' },
  { value: 'Apache Junction,Arizona', label: 'Apache Junction,Arizona' },
  { value: 'Monrovia,California', label: 'Monrovia,California' },
  { value: 'Weslaco,Texas', label: 'Weslaco,Texas' },
  { value: 'Keizer,Oregon', label: 'Keizer,Oregon' },
  { value: 'Spanish Fork,Utah', label: 'Spanish Fork,Utah' },
  { value: 'Beloit,Wisconsin', label: 'Beloit,Wisconsin' },
  { value: 'Panama City,Florida', label: 'Panama City,Florida' },
];
