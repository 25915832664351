export default {
  signUp: () => `/service_professional/profiles`,
  signIn: () => `/service_professional/sessions`,

  //bookings
  bookings: () => `/service_professional/bookings`,

  //availabilities
  availabilities: () => `/service_professional/availabilities`,
  entries: () => `/service_professional/calendar_entries`,

  //connect-account
  account: () => `/service_professional/profiles/connect_account`,
};
