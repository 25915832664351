import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import { Row, Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './store/index';

const render = AppRoutes =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Container fluid className="pl-0 pr-0 justify-content-center">
          <div>
            <ToastContainer position="top-center" autoClose={3000} />
            <AppRoutes />
          </div>
        </Container>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );

render(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
