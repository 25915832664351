import React from 'react';
import { connect } from 'react-redux';

let connectProps = {};
let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
});

let enhancer = connect(connectState, connectProps);

const ProfileItem = ({ heading, value }) => (
  <div className="my-3 py-2 border-b">
    <h2 className="font-montserrat-bold font-bold">{heading}</h2>
    <h4 className="font-montserrat-Regular mt-2 text-gray-600">{value}</h4>
  </div>
);

function Profile(props) {
  let currentUser = props.currentUser && props.currentUser.attributes;

  return (
    <div className="gray-bg-color">
      <div className="container min-h-screen">
        <div className="flex flex-col pt-5 px-5 pb-3">
          <h2>Profile</h2>
          <div className="bg-white justify-center p-5 mt-4 rounded-lg shadow-lg">
            <ProfileItem heading={'Email'} value={currentUser && currentUser.email} />
            <ProfileItem heading={'First Name'} value={currentUser && currentUser.first_name} />
            <ProfileItem heading={'Last Name'} value={currentUser && currentUser.last_name} />
            <ProfileItem heading={'City'} value={currentUser && currentUser.city} />
            <ProfileItem heading={'Contact'} value={currentUser && currentUser.contact_number} />
            <ProfileItem heading={'Postal Code'} value={currentUser && currentUser.postal_code} />
            <ProfileItem
              heading={'Social Security Number'}
              value={currentUser && currentUser.social_security_number}
            />
            <ProfileItem
              heading={'License Number'}
              value={currentUser && currentUser.license_number}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhancer(Profile);
