import React, { useState } from 'react';
import { Form, Button, Card, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { UserActions } from '../../store/actions';
import Loader from './Loader';

let connectProps = {
  ...UserActions,
};

let enhancer = connect(
  null,
  connectProps
);

function LoginForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let loader = props.loader;

  const submitSignin = async e => {
    e.preventDefault();
    props.loginParent({ email, password });
  };

  return (
    <Card className="ml-auto mr-auto bg-transparent border-0">
      <div className="flex-row d-flex justify-center">
        <div className="bg-white w-9/12 rounded-right">
          <div className="container">
            <Card.Title className="mt-4">
              <h5 className="font-montserrat-Regular font-semibold">Log In Client</h5>
            </Card.Title>

            <Card.Body>
              <Form onSubmit={submitSignin}>
                <Form.Group as={Row} controlId="email" className="mt-4">
                  <Form.Control
                    type="email"
                    className="border-t-0 border-l-0 border-r-0 font-montserrat-Regular"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />

                  <label className="form-control-placeholder text-sm" htmlFor="email">
                    Email
                  </label>
                </Form.Group>

                <Form.Group as={Row} controlId="password" className="mt-4">
                  <Form.Control
                    type="password"
                    className="border-t-0 border-l-0 border-r-0 font-montserrat-Regular"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                  />

                  <label className="form-control-placeholder text-sm" htmlFor="password">
                    Password
                  </label>
                </Form.Group>

                <div>
                  <Button
                    variant=""
                    disabled={loader}
                    type="submit"
                    className="mt-4 btn w-100 text-white font-montserrat-Regular text-xs py-2">
                    Sign In
                  </Button>
                </div>

                {loader && (
                  <div className="flex justify-center items-center">
                    <Loader small />
                  </div>
                )}

                <div className="flex-column flex align-items-center">
                  <h6 className="mt-4 font-montserrat-Regular text-xs">Do not have an account?</h6>

                  <Button
                    variant=""
                    type="submit"
                    onClick={() => props.signupParent()}
                    className="mt-2 btn w-50 text-white font-montserrat-Regular text-xs py-2">
                    Sign Up
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default enhancer(LoginForm);
