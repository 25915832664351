import React from 'react';
import { connect } from 'react-redux';
import {
  BgHome,
  Fb,
  Insta,
  Link,
  Twitter,
  academic,
  speed,
  mechanic,
  weight,
} from '../../../assets/img';
import { ClientHeader } from '../Header';
import { useHistory } from 'react-router-dom';

let connectProps = {};
let connectState = state => ({});

let enhancer = connect(
  connectState,
  connectProps
);

const TrainingCard = props => {
  return (
    <div class="max-w-xs w-64 rounded-lg overflow-hidden shadow-lg border-2 bg-transparent  border-yellow-400 items-center justify-center flex flex-col">
      <img className="my-6" src={weight} style={{ height: '100px', width: '100px' }} />
      <small className="text-white mb-10">Weight</small>
    </div>
  );
};

function ClientHome(props) {
  const history = useHistory();

  return (
    <div style={{ backgroundColor: '#04030C' }}>
      <ClientHeader />

      <div className="min-h-screen" style={{ backgroundImage: `url(${BgHome})` }}>
        <h1 style={{ fontSize: '30px' }} className=" font-semibold text-white text-center pt-40">
          Train 1V1 with the nation’s top
          <br />
          collegiate athletess
        </h1>

        <div class="w-100 flex flex-wrap">
          <div class="w-full md:w-1/5 lg:w-1/5 direction">
            <a className="py-2 px-4 ">
              <img src={Insta} alt="Logo" />
            </a>

            <a className="py-2 px-4">
              <img src={Link} alt="Logo" />
            </a>

            <a className="py-2 px-4">
              <img src={Fb} alt="Logo" />
            </a>

            <a className="py-2 px-4">
              <img src={Twitter} alt="Logo" />
            </a>
          </div>

          <div class="md:w-3/4 lg:w-3/4 w-full">
            <div className="flex custom-margin mt-5">
              <div class="flex-1 bg-gray-400 h-12">
                <input
                  class="font-normal text-sm appearance-none border-r-2 border-gray-300 w-full py-2 px-3 text-gray-700"
                  id="username"
                  type="text"
                  placeholder="Trainings, locations…"></input>
              </div>

              <div class="flex-1 bg-gray-500 h-12">
                <input
                  class="font-normal text-sm appearance-none border-r-2 border-gray-300 w-full py-2 px-3 text-gray-700"
                  id="username"
                  type="text"
                  placeholder="Sport type"></input>
              </div>

              <div class="flex-1 bg-gray-400 h-12">
                <input
                  class="font-normal text-sm w-full py-2 px-3 text-gray-700"
                  id="username"
                  type="text"
                  placeholder="Training type"></input>
              </div>

              <div class="flex-1 bg-gray-500 h-12">
                <button
                  onClick={() => {
                    history.push('/client/search');
                  }}
                  class="bg-yellow-400 hover:bg-yellow-500 font-normal text-sm py-2 px-4 h-full w-full">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 style={{ fontSize: '28px' }} className=" font-normal text-white text-center pb-10 pt-20">
        Trainings offered
      </h1>

      <div className="flex flex-row justify-between">
        <TrainingCard />
        <TrainingCard />
        <TrainingCard />
        <TrainingCard />
      </div>
    </div>
  );
}

export default enhancer(ClientHome);
