import React from 'react';
import Loader from 'react-loader-spinner';

function LoaderIndicator(props) {
  return (
    <Loader
      visible={true}
      type="ThreeDots"
      color="#fd6163"
      height={props.small ? 50 : 80}
      width={props.small ? 40 : 80}
    />
  );
}

export default LoaderIndicator;
