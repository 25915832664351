import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import SigninForm from './Signin';

function SigninModal() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal size="md" show={show} centered>
      <Row className="justify-center">
        <Col md={12}>
          <SigninForm />
        </Col>
      </Row>
    </Modal>
  );
}

export default SigninModal;
